export const GET_EARN_DATA_REQUEST = "GET_EARN_DATA_REQUEST";
export const GET_EARN_DATA_SUCCESS = "GET_EARN_DATA_SUCCESS";
export const GET_EARN_DATA_FAILED = "GET_EARN_DATA_FAILED";

export const CHECK_EARN_DATA_REQUEST = "CHECK_EARN_DATA_REQUEST";
export const CHECK_EARN_DATA_SUCCESS = "CHECK_EARN_DATA_SUCCESS";
export const CHECK_EARN_DATA_FAILED = "CHECK_EARN_DATA_FAILED";

export const getEarnDataAction = () => {
	return { type: GET_EARN_DATA_REQUEST };
};

export const checkEarnDataAction = (data,successcallback) => {
	return { type: CHECK_EARN_DATA_REQUEST,payload: data,successcallback:successcallback };
}