import {
  GET_LEVELS,
  GET_LEVELS_NOT_FOUND,
  GET_LEVEL_FOUND,
  GET_LEVEL_LEADERBOARD,
  GET_LEVEL_LEADERBOARD_FOUND,
  GET_LEVEL_LEADERBOARD_NOT_FOUND,
  GET_USER_RANK_REQUEST,
  GET_USER_RANK_SUCCESS,
  GET_USER_RANK_FAILED
} from "../actions/leaderBoardActions";

const initialState = {
  levels: [],
  levelDetails: [],
  loading: true,
  userRank:null,
  rankLoading:false
};

export const LeaderboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEVELS:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL_FOUND:
      return {
        ...state,
        levels: action?.payload,
        loading: false,
      };
    case GET_LEVELS_NOT_FOUND:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const LevelLeaderboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEVEL_LEADERBOARD:
      return {
        ...state,
        loading: true,
      };
    case GET_LEVEL_LEADERBOARD_FOUND:
      return {
        ...state,
        levelDetails: action.payload,
        userRank: action?.userRank,
        loading: false,
      };
    case GET_LEVEL_LEADERBOARD_NOT_FOUND:
      return {
        ...state,
        loading: false,
      };
    case GET_USER_RANK_REQUEST:
      return {
        ...state,
        rankLoading: true
      };
    case GET_USER_RANK_SUCCESS:
      return {
        ...state,
        rankLoading: false,
        userRank: action.payload
      };
    case GET_USER_RANK_FAILED:
      return {
        ...state,
        rankLoading: false
      };
    default:
      return state;
  }
};
