import { useEffect } from 'react'
import { useRoutes } from "react-router-dom";
import { useTelegram } from '../hooks/useTelegram';

import { Routes } from './allRoutes';
import GetAllowedRoutes from './GetAllowedRoutes'

const AppRoutes = () => {
    const { initTelegram } = useTelegram();

    useEffect(() => {
        initTelegram();
    }, []);

    const routes = useRoutes(GetAllowedRoutes([...Routes]));
    return routes;
};

export default AppRoutes;
