import { Outlet } from "react-router-dom";

const FullscreenContainer = () => {
  const url = window.location.pathname;

  return (
    <div
      className={`${url === '/leaderboard' ?'leaderboardMain' : ''} layout_wrapper flex justify-center h-screen  overflow-auto items-center`}
    >
      <div className="panel_wrp">
        <Outlet />
      </div>
    </div>
  );
};

export default FullscreenContainer;
