export const ADD_COIN_REQUEST = "ADD_COIN_REQUEST";
export const ADD_COIN_SUCCESS = "ADD_COIN_SUCCESS";
export const ADD_COIN_FAILED = "ADD_COIN_FAILED";

export const SUBTRACT_COIN_REQUEST = "SUBTRACT_COIN_REQUEST";
export const SUBTRACT_COIN_SUCCESS = "SUBTRACT_COIN_SUCCESS";
export const SUBTRACT_COIN_FAILED = "SUBTRACT_COIN_FAILED";

export const CLAIM_REWARD_REQUEST = "CLAIM_REWARD_REQUEST";
export const CLAIM_REWARD_SUCCESS = "CLAIM_REWARD_SUCCESS";
export const CLAIM_REWARD_FAILED = "CLAIM_REWARD_FAILED";

export const REPORT_COINS_REQUEST = "REPORT_COINS_REQUEST";
export const REPORT_COINS_SUCCESS = "REPORT_COINS_SUCCESS";
export const REPORT_COINS_FAILED = "REPORT_COINS_FAILED";

export const UPDATE_LOCAL_COINS = "UPDATE_LOCAL_COINS";
export const ADD_TO_LOCAL_COINS = "ADD_TO_LOCAL_COINS";
export const UPDATE_ENERGY = "UPDATE_ENERGY";
export const DECREASE_ENERGY = "DECREASE_ENERGY";
export const INCREASE_ENERGY = "INCREASE_ENERGY";
export const INCREASE_CLICKS = "INCREASE_CLICKS";

export const GET_TOTAL_USERS = "GET_TOTAL_USERS";
export const GET_TOTAL_USERS_RESPONSE = "GET_TOTAL_USERS_RESPONSE";
export const GET_TOTAL_USERS_FAILED = "GET_TOTAL_USERS_FAILED";

export const CHECK_USER_COINS = "CHECK_USER_COINS";
export const CHECK_USER_COINS_RESPONSE = "CHECK_USER_COINS_RESPONSE";
export const CHECK_USER_COINS_FAILED = "CHECK_USER_COINS_FAILED";

export const REPORT_CLICKS = "REPORT_CLICKS";

export const AddCoinAction = (data) => {
  return { type: ADD_COIN_REQUEST, payload: data };
};

export const SubtractCoinAction = (data) => {
  return { type: SUBTRACT_COIN_REQUEST, payload: data };
};

export const ClaimRewardAction = (data, successCallback) => {
  return {
    type: CLAIM_REWARD_REQUEST,
    payload: data,
    successCallback: successCallback,
  };
};

const appVersion = import.meta.env.VITE_APP_VERSION;

export const ReportCoinsAction = (data, userObj) => {
  return {
    type: REPORT_COINS_REQUEST,
    payload: data,
    userObj,
    appVersion,
  };
};

export const UpdateLocalCoinsAction = (data) => {
  return { type: UPDATE_LOCAL_COINS, payload: data };
};

export const AddToLocalCoinsAction = (data) => {
  return { type: ADD_TO_LOCAL_COINS, payload: data };
};

export const UpdateEnergyAction = (data) => {
  return { type: UPDATE_ENERGY, payload: data };
};

export const DecreaseEnergyAction = () => {
  return { type: DECREASE_ENERGY };
};

export const IncreaseEnergyAction = () => {
  return { type: INCREASE_ENERGY };
};

export const IncreaseClicksAction = () => {
  return { type: INCREASE_CLICKS };
};

export const GetTotalUsers = () => {
  return { type: GET_TOTAL_USERS };
};

export const CheckUserCoins = () => {
  return { type: CHECK_USER_COINS };
};
