import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserPointsKey } from '../general.js';
import { updateUserLevelAction } from '../redux/actions/authActions.js';

export function useCoins() {
  const dispatch = useDispatch();
  const AuthState = useSelector((state) => state.Auth);
  const LeaderboardState = useSelector((state) => state.Leaderboard);
  const GameState = useSelector((state) => state.Game);
  const { user } = AuthState;
  const { levels } = LeaderboardState;
  const { coins } = GameState;
  const currentCoinsRef = useRef(coins);

  useEffect(() => {
    currentCoinsRef.current = coins;
    localStorage.setItem(UserPointsKey, coins);
    if (coins > parseInt(user?.levl_max_range))  {
      updateUserLevel();
    }
  }, [coins, user?.levl_max_range]);

  const updateUserLevel = ()=> {
    if (levels && levels.length > 0) {
      const newLevel = levels.find((l) => l.id === user?.level + 1);
      if (newLevel) {
        const userUpdate = {
          ...user,
          level: newLevel.id,
          level_name: newLevel.title,
          levl_min_range: newLevel.min_range,
          levl_max_range: newLevel.max_range,
        };
        dispatch(updateUserLevelAction(userUpdate))
      }
    }
  }

  return {
    currentCoins: coins,
    currentCoinsRef,
  }
}
