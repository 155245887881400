import axios from "../../config/axiosConfig";
import { showErrorToast } from "../../utils/functions";
import { GET_REWARD_DATA_SUCCESS, GET_REWARD_DATA_FAILED, CHECK_REWARD_DATA_SUCCESS, CHECK_REWARD_DATA_FAILED  } from "../actions/rewardActions";
import { call, put } from "redux-saga/effects";

export function* getDailyRewardSaga(action) {
  try {
    const { data, status } = yield call(axios.get, "daily_rewards");
    if (status === 200 || status === 201 || status === 202) {
		yield put({  type: GET_REWARD_DATA_SUCCESS, payload: data }); 
        if(action?.successcallback){  
            let successcallback = action?.successcallback;
            successcallback(status,data);
        }
    }
  } catch (e) {
    yield* showErrorToast();
		yield put({ type: GET_REWARD_DATA_FAILED });
  }
}

export function* checkDailyRewardSaga(action) {
  try {
    const { data, status } = yield call(axios.get, "check_rewards");
    if (status === 200 || status === 201 || status === 202) {      
      if (action?.successcallback) {
        action.successcallback(data);
      }
      yield put({ type: CHECK_REWARD_DATA_SUCCESS, payload: data });
    } else {
      if (action?.onFailed) {
        action.onFailed();  // Call onFailed if the request is not successful
      }
      yield put({ type: CHECK_REWARD_DATA_FAILED });
    }
  } catch (e) {
    if (action?.onFailed) {
      action.onFailed();  // Call onFailed in case of an exception
    }
    yield* showErrorToast();
    yield put({ type: CHECK_REWARD_DATA_FAILED });
  }
}
