import { call, put } from "redux-saga/effects";

import axios from "../../config/axiosConfig";
import { GET_BOOSTER_SUCCESS,GET_BOOSTER_FAILED,GET_DAILY_BOOSTER_SUCCESS,GET_DAILY_BOOSTER_FAILED } from "../actions/boostersActions";
import { showErrorToast } from "../../utils/functions";
 


export function* GetBoostersSaga() {
	try {
		const response = yield call(axios.get, "get_boosters"); 
		if (response?.status === 200) {
			yield put({ type: GET_BOOSTER_SUCCESS,payload: response?.data });    
		} 
	} catch (e) { 
		yield* showErrorToast();
		yield put({ type: GET_BOOSTER_FAILED });
	}
}


export function* GetDailyBoosterSaga() {
	try {
		const response = yield call(axios.get, "get_daily_boosters"); 
		if (response?.status === 200) {
			yield put({ type: GET_DAILY_BOOSTER_SUCCESS,payload: response?.data });    
		} 
	} catch (e) { 
		yield* showErrorToast();
		yield put({ type: GET_DAILY_BOOSTER_FAILED });
	}
}