import QRImage from "../../assets/images/qr_Code-image.svg";

const ScanQR = () => {
	return (
		<div className="h-full bg-black"> 
			<img src={QRImage} alt="QRImage" className="h-full"/>
		</div>
	);
};

export default ScanQR;
