import {
  GET_BOOSTER_REQUEST,
  GET_BOOSTER_FAILED,
  GET_BOOSTER_SUCCESS,
  GET_DAILY_BOOSTER_FAILED,
  GET_DAILY_BOOSTER_REQUEST,
  GET_DAILY_BOOSTER_SUCCESS,
  SET_CLICK_COUNT,
} from "../actions/boostersActions";

const initialState = {
  loading: true,
  booster: [],
  daily_booster: [],
  clickCount:parseInt(localStorage.getItem("powerDailyBooster_clickCount")) || 0,
};

export const BoostersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOSTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOSTER_SUCCESS:
      return {
        ...state,
        booster: action?.payload,
        loading: false,
      };
    case GET_BOOSTER_FAILED:
      return {
        ...state,
        booster: [],
        loading: false,
      };
    case GET_DAILY_BOOSTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DAILY_BOOSTER_SUCCESS:
      return {
        ...state,
        daily_booster: action?.payload,
        loading: false,
      };
    case GET_DAILY_BOOSTER_FAILED:
      return {
        ...state,
        daily_booster: [],
        loading: false,
      };
    case SET_CLICK_COUNT:
      localStorage.setItem("powerDailyBooster_clickCount", action?.payload);
      return {
        ...state,
        clickCount: action?.payload,
        loading: false,
      };
    default:
      return state;
  }
};
