export const footerHeight = 100;

export const UserLevelImages = [
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/cub_lion.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/pride_member.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/hunter.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/protector.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/noble.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/vizier.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/sheik.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/emir.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/sultan.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/lion.png",
];

export const LeaderboardLevelBg = [
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelOne.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelTwo.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelThree.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelFour.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelFive.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelSix.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelSeven.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelEight.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelNine.png",
  "https://d231jkf923t0p3.cloudfront.net/levels-v2/bg/levelTen.png",
];

// User types
export const UserTypeRegistered = "Registered";
export const UserTypeReturning = "Returning";

// Local storage keys
export const UserPointsKey = "user_points";
export const ClickCountKey = "click_count";
export const EnergyCountKey = "energy_count";
export const EnergyLastUpdatedKey = "energy_last_updated";

//const ClipPopupShownKey = 'clip_popup_shown';

// Coin types
export const CoinTypeClick = "click";
export const CoinTypeReported = "reported_coins";
