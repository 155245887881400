import { put } from "redux-saga/effects";
import { TOAST_SHOW } from "../redux/actions/toastActions";
import { t } from "../i18n";

export const chunkArray = (array, chunkSize) => {
	// Validate input
	if (
		!Array.isArray(array) ||
		!Number.isInteger(chunkSize) ||
		chunkSize <= 0
	) {
		throw new Error(
			"Invalid input. Please provide a valid array and a positive chunk size."
		);
	}

	const result = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize));
	}

	return result;
}; 
  

export const setCookie = (name, value, expirationTime)=> {
    const expires = "expires=" + expirationTime.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const removeCookie =(name)=> {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

//USED TO CHECK COOKIE EXISTS OR NOT.
export const checkCookie =(name)=>{
	// Split the document.cookie string into individual cookies
	const cookies = document.cookie.split(';');
  
	// Iterate through the cookies to find the one with the specified name
	for (let cookie of cookies) {
		// Trim any leading or trailing spaces from the cookie string
		cookie = cookie.trim();
		// Check if the cookie starts with the specified name followed by "="
		if (cookie.startsWith(name + '=')) {
			return true; // Cookie found
		}
	}
  
	return false; // Cookie not found
}

export const formatDate =(dateString)=>{
    // Parse the given string into a Date object
    const date = new Date(dateString);

    // Extract date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Construct the formatted date string
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}

export const formatCmpctNumber=(number)=>{
	const usformatter = Intl.NumberFormat("en-US", { notation: "compact",compactDisplay: "short"});
	return usformatter.format(number);
}

export function* showErrorToast(title, message, button) {
	if (!title) {
		title = t("general.error_title");
	}
	if (!message) {
		message = t("general.error_text");
	}
	if (!button) {
		button = t("general.error_button");
	}
	yield put({
		type: TOAST_SHOW,
		payload: {
		title,
		message,
		button: button,
		severity: "danger",
		show: true,
		},
	});
}

export function dispatchErrorToast(dispatch, title, message, button) {
	if (!title) {
		title = t("general.error_title");
	}
	if (!message) {
		message = t("general.error_text");
	}
	if (!button) {
		button = t("general.error_button");
	}	
	dispatch({
		type: TOAST_SHOW,
		payload: {
			title: title,
			message: message,
			severity: "danger",
			button: button,
			show: true,
		},
	});
}