import {
	GET_REWARD_DATA_REQUEST,
	GET_REWARD_DATA_FAILED,
	GET_REWARD_DATA_SUCCESS,
	CHECK_REWARD_DATA_REQUEST,
	CHECK_REWARD_DATA_SUCCESS,
	CHECK_REWARD_DATA_FAILED
} from "../actions/rewardActions";

const initialState = {
	loading: false,
	check_loading: false,
	reward_data: {},
	check_reward_data: [],
};

export const RewardReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_REWARD_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_REWARD_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				reward_data: action?.payload, 
			};
		case GET_REWARD_DATA_FAILED:
			return {
				...state,
				loading: false,
				reward_data: {},
			};
		case CHECK_REWARD_DATA_REQUEST:
			return {
				...state,
				check_loading: true,
			};
		case CHECK_REWARD_DATA_SUCCESS:
			return {
				...state,
				check_reward_data: action?.payload,
				check_loading: false, 
			};
		case CHECK_REWARD_DATA_FAILED:
			return {
				...state,
				check_reward_data: [],
				check_loading: false, 
			};
		default:
			return state;
	}
};