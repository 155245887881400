import ReactLoading from 'react-loading';
import { useTranslation } from "react-i18next";

const Loader = () => {
  const { t  } = useTranslation();   

  return (
    <div className='flex flex-col items-center justify-center gap-1 min-h-screen'> {/* Add min-h-screen */}
      <ReactLoading  type={'spin'} color={'#e4dc8d'} height={35} width={35} />
      <h1>{t('general.loading')}</h1>
    </div>
  );
};

export default Loader;
