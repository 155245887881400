import { all, takeEvery } from "redux-saga/effects";

import {
	GET_USER_REQUEST,
	LOGOUT,
	DELETE_ACCOUNT_REQUEST,
	UPDATE_LANGUAGE_REQUEST,
} from "../actions/authActions";
import { TOAST_INIT } from "../actions/toastActions";
import {
	ADD_COIN_REQUEST,
	SUBTRACT_COIN_REQUEST,
	REPORT_COINS_REQUEST,
	GET_TOTAL_USERS,
	CHECK_USER_COINS,
	INCREASE_CLICKS,
	REPORT_CLICKS,
} from "../actions/gameActions";
import {
	GET_BOOSTER_REQUEST,
	GET_DAILY_BOOSTER_REQUEST,
} from "../actions/boostersActions";
import {
	GET_LEVELS,
	GET_LEVEL_LEADERBOARD,
	GET_USER_RANK_REQUEST,
} from "../actions/leaderBoardActions";
import {
	CHECK_EARN_DATA_REQUEST,
	GET_EARN_DATA_REQUEST,
} from "../actions/earnActions";
import {
	CHECK_FRIENDS_REQUEST,
	CLAIM_REWARD_FRIENDS_REQUEST,
} from "../actions/friendsActions";
import {
	GET_POPUP_MESSAGE_REQUEST,
	MESSAGE_READ_REQUEST,
} from "../actions/messageActions";

import {
	CHECK_REWARD_DATA_REQUEST,
	GET_REWARD_DATA_REQUEST,
} from "../actions/rewardActions";

import {
	RESET_REWARDS_DATA_REQUEST,
	SET_REWARD_DAY_REQUEST,
} from "../actions/debugActions";

import {
	LoginSaga,
	LogoutSaga,
	DeleteAccountSaga,
	UpdateLanguageSaga,
} from "./AuthSaga";
import { ToastSaga } from "./ToastSaga";
import {
	AddCoinSaga,
	SubtractCoinSaga,
	ReportCoinSaga,
	getTotalUsersSaga,
	checkUserCoinsSaga,
	trackClicksSaga,
	initUserCoinsSaga,
	syncCoinsSaga,
} from "./GameSaga";
import {
	LeaderBoardSaga,
	LevelLeaderboardSaga,
	getUserLeaderBoardRankSaga,
} from "./LeaderBoardSaga";
import { GetBoostersSaga, GetDailyBoosterSaga } from "./BoostersSaga";
import { getEarnDataSaga, checkEarnDataSaga } from "./EarnSaga";
import { checkFriendsSaga, claimRewardsFriendsSaga } from "./FriendsSaga";
import { getPopupMessageSaga, readMessageSaga } from "./messageSaga";
import { checkDailyRewardSaga, getDailyRewardSaga } from "./RewardSaga";
import { resetDailyRewards, setDailyRewardsDays } from "./DebugSaga";

function* rootSagas() {
	yield all([
		takeEvery(GET_USER_REQUEST, LoginSaga),
		takeEvery(LOGOUT, LogoutSaga),
		takeEvery(TOAST_INIT, ToastSaga),
		takeEvery(ADD_COIN_REQUEST, AddCoinSaga),
		takeEvery(SUBTRACT_COIN_REQUEST, SubtractCoinSaga),
		takeEvery(GET_LEVELS, LeaderBoardSaga),
		takeEvery(GET_LEVEL_LEADERBOARD, LevelLeaderboardSaga),
		takeEvery(DELETE_ACCOUNT_REQUEST, DeleteAccountSaga),
		takeEvery(GET_BOOSTER_REQUEST, GetBoostersSaga),
		takeEvery(GET_DAILY_BOOSTER_REQUEST, GetDailyBoosterSaga),
		takeEvery(GET_EARN_DATA_REQUEST, getEarnDataSaga),
		takeEvery(CHECK_EARN_DATA_REQUEST, checkEarnDataSaga),
		takeEvery(CHECK_FRIENDS_REQUEST, checkFriendsSaga),
		takeEvery(CLAIM_REWARD_FRIENDS_REQUEST, claimRewardsFriendsSaga),
		takeEvery(GET_USER_RANK_REQUEST, getUserLeaderBoardRankSaga),
		takeEvery(GET_USER_RANK_REQUEST, getUserLeaderBoardRankSaga),
		takeEvery(REPORT_COINS_REQUEST, ReportCoinSaga),
		takeEvery(UPDATE_LANGUAGE_REQUEST, UpdateLanguageSaga),
		takeEvery(GET_POPUP_MESSAGE_REQUEST, getPopupMessageSaga),
		takeEvery(MESSAGE_READ_REQUEST, readMessageSaga),
		takeEvery(GET_TOTAL_USERS, getTotalUsersSaga),
		takeEvery(CHECK_USER_COINS, checkUserCoinsSaga),
		takeEvery(INCREASE_CLICKS, trackClicksSaga),
		takeEvery(REPORT_CLICKS, ReportCoinSaga),
		takeEvery(GET_REWARD_DATA_REQUEST, getDailyRewardSaga),
		takeEvery(CHECK_REWARD_DATA_REQUEST, checkDailyRewardSaga),
		takeEvery(RESET_REWARDS_DATA_REQUEST, resetDailyRewards),
		takeEvery(SET_REWARD_DAY_REQUEST, setDailyRewardsDays),
		takeEvery("@@INIT", initUserCoinsSaga),
		syncCoinsSaga(),
	]);
}
export default rootSagas;
