import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {GetBoostersAction,GetDailyBoosterAction,setClickCount} from "../../redux/actions/boostersActions";
import Footer from "../../components/Footer/Footer";
import dollarCoin from "../../assets/images/dollorCoin.png";
import greyDollarIcon from "../../assets/images/greyDollorIcon.png";
import { useBackButton } from "../../hooks/useBackButton.jsx";
import { getPopupMessageAction } from "../../redux/actions/messageActions.js";
import { useEnergy } from '../../hooks/useEnergy.js';
import "./style.css";
import { GameDailyBonusClickAction } from '../../redux/actions/googleAnalyticsActions.js';
import Loader from '../../components/UI/Loader';

const BoostRewards = () => {
	useBackButton("/boost-rewards", "/home");
	const [lastClickedDate, setLastClickedDate] = useState(null);
	const [boostDisabled, setBoostDisabled] = useState(false);
	const [showTimer, setShowTimer] = useState(false);
	//const [revive, setRevive] = useState(null);
	const [timeLeft, setTimeLeft] = useState(null);
	const { t } = useTranslation();
	const { energy, update: updateEnergy } = useEnergy();
	const dispatch = useDispatch();

	const boosters = useSelector((state) => state.Boosters);

	useEffect(() => {
		dispatch(getPopupMessageAction());
		dispatch(GetBoostersAction());
		dispatch(GetDailyBoosterAction());
		dispatch(GameDailyBonusClickAction())

		const today = moment().format("L");
		let powerDailyBooster_clicked = localStorage.getItem("powerDailyBooster_clicked");
		powerDailyBooster_clicked = moment(powerDailyBooster_clicked).format("L");
		let powerDailyBooster_times = localStorage.getItem("powerDailyBooster_times");
		let powerDailyBooster_clickCount = localStorage.getItem("powerDailyBooster_clickCount");

		if (powerDailyBooster_clicked !== today) {
			localStorage.removeItem("powerDailyBooster_timerEndTime");
			localStorage.setItem("powerDailyBooster_clickCount", 0);
			dispatch(setClickCount(0));
			setBoostDisabled(false);
			setShowTimer(false);
			return;
		}

		let energyCount = localStorage.getItem("energy_count");		
		if (energyCount == 1000) {		
			setBoostDisabled(true);
			setShowTimer(false);
			return;
		}

		if (powerDailyBooster_clicked === today && (JSON.parse(powerDailyBooster_times) - JSON.parse(powerDailyBooster_clickCount)) <= 0) {
			setBoostDisabled(true);
			setShowTimer(true);
		}

		if (  (powerDailyBooster_times && powerDailyBooster_clickCount) && ((JSON.parse(powerDailyBooster_times) - JSON.parse(powerDailyBooster_clickCount)) <= 0)) {
			setBoostDisabled(true);
			setShowTimer(false);
		}else{
			setBoostDisabled(false);
			setShowTimer(false);
		}

	}, []);

	// Set an interval to check energyCount every second
	useEffect(() => {
		const energyInterval = setInterval(() => {
		  let energyCount = localStorage.getItem("energy_count");
		  if (energyCount == 1000) {
			if (!boostDisabled) {
				setBoostDisabled(true);				
			}	
			clearInterval(energyInterval); // Stop checking once the limit is reached		
		  }
		}, 1000);
	
		return () => clearInterval(energyInterval); // Cleanup on unmount
	  }, []);

	useEffect(() => {
		const savedEndTime = localStorage.getItem("powerDailyBooster_timerEndTime");
		if (savedEndTime) {
			const remainingTimemoment = moment(savedEndTime).diff(moment(),"milliseconds");
			if (remainingTimemoment > 0) {
				setTimeLeft(remainingTimemoment);
				setBoostDisabled(true);
				setShowTimer(true);
			}else{
				localStorage.removeItem("powerDailyBooster_timerEndTime");
                if((parseInt(localStorage.getItem("powerDailyBooster_times")) - parseInt(localStorage.getItem("powerDailyBooster_clickCount"))) > 0){
                    setBoostDisabled(false);
                }
                setShowTimer(false);
			}
		}


		const interval = setInterval(() => {
			if (timeLeft !== null) {
				setTimeLeft((prevTime) => {
					const newTime = prevTime - 1000;
					if (newTime <= 0) {
						clearInterval(interval);
						localStorage.removeItem("powerDailyBooster_timerEndTime");
						let powerDailyBooster_times = localStorage.getItem("powerDailyBooster_times");
						let powerDailyBooster_clickCount = localStorage.getItem("powerDailyBooster_clickCount");
				
						if((JSON.parse(powerDailyBooster_times) - JSON.parse(powerDailyBooster_clickCount)) > 0){
							setBoostDisabled(false);
						}
						setShowTimer(false);
						return null;
					}
					
					return newTime;
				});
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [timeLeft]);

	const formatTimeLeft = (milliseconds) => {
		const totalSeconds = Math.floor(milliseconds / 1000);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;

		return `${minutes.toString().padStart(2, "0")}:${seconds
			.toString()
			.padStart(2, "0")}`;
	};

	const canClick = (day) => {
		const currentDate = new Date();
		console.log("Debug","day", day);
		
		console.log("Debug","lastClickedDate", lastClickedDate);

		console.log("Debug","currentDate", currentDate);

		if (!lastClickedDate || lastClickedDate.getDate() !== currentDate.getDate()) {
			setLastClickedDate(currentDate);
			localStorage.setItem("powerDailyBooster_clicked", moment());
			//localStorage.setItem("powerDailyBooster_clickCount", 0);
			dispatch(setClickCount(0));
			setBoostDisabled(false);
		}
	
		return (JSON.parse(localStorage.getItem("clickCount")) < day && !boostDisabled);
	};

	const handleClick = (amountDay, reviveDuration) => {
		let day = JSON.parse(amountDay);
		// const endTime = new Date().getTime() + 60 * 60 * 1000;
		const endTime = moment().add(1, "hour").format();
		localStorage.setItem("powerDailyBooster_timerEndTime", endTime);
		setTimeLeft(60 * 60 * 1000);
		localStorage.setItem("powerDailyBooster_clicked", moment());
		localStorage.setItem("powerDailyBooster_times", day);
		//setRevive(reviveDuration);
		if (canClick(day)) {
			let counts = boosters.clickCount + 1;
			dispatch(setClickCount(counts));
			setBoostDisabled(true);
			if (counts === day) {
				setShowTimer(false);
			} else {
				setShowTimer(true);
			}
			updateEnergy(1000);
		}
	};

	const checkpendingDay = (amountDay,clickCount) => {
		
		if (clickCount) {
			return JSON.parse(amountDay) - JSON.parse(clickCount);
		} else {
			return amountDay;
		}
	};
	
	return (
		<div className="earnBg h-full text-white overflow-auto pb-[70px] coustom-scroll ">
			{boosters?.loading ? (
				<div className="mt-8">
					<Loader />{" "}
				</div>
			) : (
			<>
				<div className="wrapperScroll">
					<div className="px-[10px]">
						<div className="mt-5">
							<p className="text-center text-[#a5a5a5] text-sm font-semibold">
								{t("roars.title")}
							</p>
							<div className="flex items-center justify-center gap-3 py-3">
								<img src={dollarCoin} alt="dollorCoin" />
								<label className="text-lg text-[#f2f2f2] font-bold">
									{localStorage
										.getItem("user_points")
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								</label>
							</div>
							<p className="text-center font-bold text-sm text-[#f3b806]">
								{t("roars.description")}
							</p>
							<div className="mt-3">
								{boosters?.daily_booster?.length > 0 && (
								<>
									<label className="text-[#f2f2f2] ps-3 mb-2 text-sm font-semibold">
									{t("roars.daily_roars")}
									</label>

									{boosters.daily_booster.map((item, index) => (
									<div key={index} className="position-relative mb-3">
										<button
										onClick={() => handleClick(item?.amount_day, item.revive_duration)}
										disabled={boostDisabled}
										className={`energyBtn w-100 ${
											boostDisabled ? "disabled" : ""
										} flex items-center rounded-2xl buttonBg`}
										>
										<div className="">
											<img src={item.icon} alt="palm" />
										</div>
										<div className="flex flex-col text-left">
											<h4 className="text-xl font-bold text-[#f2f2f2]">
											{item?.title || ""}
											</h4>
											<p className="text-[#a5a5a5] text-sm">
											{checkpendingDay(item.amount_day, boosters.clickCount)}
											</p>
										</div>
										</button>
										{showTimer && (
										<span className="timer text-[#f3b806]">
											Revive after {formatTimeLeft(timeLeft)}
										</span>
										)}
									</div>
									))}
								</>
								)}

								{boosters?.daily_booster?.length === 0 &&
									!boosters?.loading && (
										<div className="noFound">
											<h2 className="text-center text-white text-xl py-12">
												{t("roars.no_results")}
											</h2>
										</div>
									)}

								{boosters?.booster?.length > 0 && (
								<>
									<label className="text-[#f2f2f2] ps-3 mb-2 text-sm font-semibold">
									{t("roars.roars")}
									</label>

									{boosters.booster.map((item, index) => (
									<button
										key={index}
										className="w-100 mb-3 flex items-center rounded-2xl buttonBg"
									>
										<div className="">
										<img src={item.icon} alt="asset_21" />
										</div>
										<div className="flex flex-col text-left">
										<h4 className="text-xl font-bold text-[#f2f2f2]">
											{item?.title || "--"}
										</h4>
										<p className="text-[#a5a5a5] text-sm">
											<img src={greyDollarIcon} alt="greyDollorIcon" />
											<span className="w-2 h-2 rounded-full"></span>{" "}
											{item.description || "--"}
										</p>
										</div>
									</button>
									))}
								</>
								)}
								
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</>
			)}
		</div>
	);
};

export default BoostRewards;
