import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

import { IoMdCheckmark } from 'react-icons/io';
import { FaChevronRight } from 'react-icons/fa6';
import { UpdateLanguageAction } from '../../redux/actions/authActions';
import { LangChangeClickAction } from '../../redux/actions/googleAnalyticsActions';

const languageOptions = [
  { language: "English", code: "en", altcode:'en-US' },
  { language: "العربي", code: "ar", altcode:'ar' },
  { language: "한국인", code: "ko", altcode:'ko' },
  { language: "Português ", code: "pt",altcode:'pt'} 
];

const SelectLanguage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch(); 


  const handleChangeLang =(code)=>{
    i18n.changeLanguage(code).then(()=>{
      dispatch(LangChangeClickAction({ lang: code }));
      dispatch(UpdateLanguageAction({ lang: code }));
    })
  }

  return (
    <div className="settingsPage h-full text-white p-[10px]">
      {/* <div className="flex items-center justify-around py-0 px-1">
        <button className="flex items-center text-white text-xs p-2" onClick={goBack}>
          <ArrowLeftIcon /></button>
          <div className="font-bold justify-center gap-2 flex items-center whitespace-nowrap text-md text-white">
            <img className="w-6 h-6" src={star} alt='star'/> ASSAD <img  className="w-6 h-6"  src={star} alt='star'/>
          </div>
        <Dropdownmenu />
      </div> */}

      <h1 className="text-center font-bold text-[30px] mt-5 pt-2">{t('settings.select_language')}</h1>
      <div>
        {languageOptions.map((option, index) => (
          <div key={`lang_${index}`} className="flex items-center justify-between gap-[16px] h-[65px] rounded-[10px] bottom-2.5 inset-x-2.5 buttonBg p-2 my-3 cursor-pointer" onClick={()=>{
            handleChangeLang(option.code)
          }}>
            <div>
              <h3 className="font-bold">{option.language}</h3>
            </div>
            {
              i18n?.language && (i18n?.language === option.code || i18n?.language === option.altcode ) ?
              <IoMdCheckmark className="text-[#86898e]" size={22} />

              :
              <FaChevronRight className="text-[#86898e]" />
            }

          </div>
        ))}
      </div>
    </div>
  )
}

export default SelectLanguage
