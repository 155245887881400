import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { footerHeight, LeaderboardLevelBg } from '../../general.js';
import { GetLevelLeaderboardAction, GetLevelsAction } from '../../redux/actions/leaderBoardActions';
import Slider from '../../components/slider';
import Footer from '../../components/Footer/Footer';
import { useBackButton } from '../../hooks/useBackButton.jsx';
import { preventCloseWithSwipe } from '../../utils/preventCloseWithSwipe.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import LeaderboardItem from './leaderboardItem.jsx';
import './style.css';
import { GameLeaderboardLevelViewAction } from '../../redux/actions/googleAnalyticsActions.js';
import AvatarCanvas from '../Avatar/AvatarCanvas.jsx';

const Leaderboard = () => {
  useBackButton("/leaderboard", "/home");
  const dispatch = useDispatch();
  const leaderboard = useSelector((state) => state.Leaderboard);
  const containerRef = useRef(null);
  const levelLeaderboard = useSelector((state) => state.LevelLeaderboard);
  const AuthState = useSelector((state) => state.Auth);
  const GameState = useSelector((state) => state.Game);
  const { user } = AuthState;
  const { levelDetails, userRank } = levelLeaderboard;
  const { coins } = GameState;
  const { t } = useTranslation();
  const [currentLevel, setCurrentLevel] = useState(user?.level ?? 1);

  useEffect(() => {
    dispatch(GameLeaderboardLevelViewAction({level:currentLevel}));
    dispatch(GetLevelsAction());
    dispatch(getPopupMessageAction());
    preventCloseWithSwipe();
  }, []);

  useEffect(() => {
    fetchLevelLeaderboard(currentLevel);
  }, [leaderboard.levels]);

  const trailingUserDetails = useMemo(() => {
    if (user?.level !== currentLevel) return undefined;
    const foundUser = levelDetails.find((item) => item.id === user.assad_id);
    if (foundUser) return undefined;
    return {
      assad_id: user.assad_id,
      first_name: user.first_name,
      username: user.username,
      avatar: user.avatar,
      coins,
    };
  }, [levelDetails]);

  const fetchLevelLeaderboard = (level) => {
    if (leaderboard.levels?.length) {
      dispatch(GetLevelLeaderboardAction({level, coins} ));
    }
  }

  const handeLevelChange = (newLevel) => {
    setCurrentLevel(newLevel);
    fetchLevelLeaderboard(newLevel);
  }

  return (
    <div className="exchangePage">
      <div
        className="flex flex-col h-full"
        style={{ height: `calc(100dvh - ${footerHeight}px)` }}
      >
        <div
          className="sliderWrapper h-[280px] min-h-[280px] pt-12 pb-3 overflow-y-hidden"
          style={{
            backgroundImage: `url(${LeaderboardLevelBg[currentLevel-1]})`,
            borderTopLeftRadius: "40px",
            borderTopRightRadius: "40px",
            overflow: "hidden",
          }}
        >
          {leaderboard.levels?.length !== 0 &&
            <Slider level={currentLevel} onLevelChange={handeLevelChange} />
          }
        </div>

        <div className="bottomBlock overflow-y-auto px-3" ref={containerRef}>
          {levelDetails?.length !== 0 && (
            <>
              {levelDetails.map((item, index) => (
                <LeaderboardItem
                  key={item.id}
                  item={item}
                  rank={index + 1}
                  isSticky={item.id === user.assad_id}
                />
              ))}
              {trailingUserDetails && (
                <LeaderboardItem
                  item={trailingUserDetails}
                  rank={userRank}
                  isSticky
                />
              )}
            </>
          )}
          {!levelDetails?.length &&
            !levelLeaderboard.loading && (
              <div className="noFound">
                <h2 className="text-center text-white text-xl py-12">
                  {t('leaderboards.no_results')}
                </h2>
              </div>
            )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Leaderboard;
