export const GET_BOOSTER_REQUEST = "GET_BOOSTER_REQUEST";
export const GET_BOOSTER_SUCCESS = "GET_BOOSTER_SUCCESS";
export const GET_BOOSTER_FAILED = "GET_BOOSTER_FAILED";
export const GET_DAILY_BOOSTER_REQUEST = "GET_DAILY_BOOSTER_REQUEST";
export const GET_DAILY_BOOSTER_SUCCESS = "GET_DAILY_BOOSTER_SUCCESS";
export const GET_DAILY_BOOSTER_FAILED = "GET_DAILY_BOOSTER_FAILED";
export const SET_CLICK_COUNT = "SET_CLICK_COUNT";

export const GetBoostersAction = () => {
  return { type: GET_BOOSTER_REQUEST };
};

export const GetDailyBoosterAction = () => {
  return { type: GET_DAILY_BOOSTER_REQUEST };
};

export const setClickCount = (count) => {
  return { type: SET_CLICK_COUNT, payload: count };
};
