import { intersection } from "lodash";
import { useSelector } from "react-redux";

function isArrayWithLength(arr) {
    return (Array.isArray(arr) && arr.length)
}

const GetAllowedRoutes = (routes) => {
    const AuthState = useSelector((state) => state.Auth);
    const roles = AuthState?.user ? [] : [];


    return routes.filter((route) => { 
        if (route?.permission && sessionStorage.getItem('token')) return  true;
        if (route?.permission && !sessionStorage.getItem('token')) return  false;
        if (!route?.permission) return true;
        else if (!isArrayWithLength(route?.permission)) return true;
        else return intersection(route?.permission, roles).length;
    });
}

export default GetAllowedRoutes;