import FullscreenContainer from "../containers/FullscreenContainer";
import AuthContainer from "../containers/AuthContainer";

import Dashboard from "../views/dashboard/Dashboard";
import Settings from "../views/settings/Settings";
import SelectLanguage from "../views/settings/SelectLanguage";
import Earn from "../views/earn/Earn";
import Friends from "../views/Friends/Friends";

import NotFound from "../views/common/NotFound";
import BoostRewards from "../views/BoostRewards/BoostRewards";
import Leaderboard from "../views/Leaderboard/leaderboard";
import ScanQR from "../views/scan/ScanQR";
import Homepage from "../views/home/Homepage";
import AkedoGames from "../views/AkedoGames/AkedoGames";
import Airdrop from "../views/AirDrop/AirDrop";
import JsonUpdateComponent from "../views/JsonUpdate/JsonUpdateComponent";
import OnBoardingScreen from "../views/OnBoardingScreen/OnBoardingScreen";

export const Routes = [
	{
		path: "/",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <Dashboard />,
			},
		],
	},
	{
		path: "/settings",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <Settings />,
			},
		],
	},
	{
		path: "/change-language",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <SelectLanguage />,
			},
		],
	},
	{
		path: "/earn",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <Earn />,
			},
		],
	},
	{
		path: "/friends",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <Friends />,
			},
		],
	},
	{
		path: "/boost-rewards",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <BoostRewards />,
			},
		],
	},
	{
		path: "/leaderboard",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <Leaderboard />,
			},
		],
	},
	{
		path: "/scan-qr",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <ScanQR />,
			},
		],
	},
	{
		path: "/onboarding",
		element: <FullscreenContainer />,
		children: [
			{
				index: true,
				element: <OnBoardingScreen />,
			},
		],
	},
	{
		path: "/home",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <Homepage />,
			},
		],
	},
	{
		path: "/akedo-games",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <AkedoGames />,
			},
		],
	},
	{
		path: "/airdrop",
		element: <AuthContainer />,
		children: [
			{
				index: true,
				element: <Airdrop />,
			},
		],
	},
	{
		path: "/json-update",
		element: <JsonUpdateComponent />,
	},
	{
		path: "*",
		element: <NotFound />,
	},
];
