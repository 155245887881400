const overflow = 100;

// taken from https://github.com/deptyped/vue-telegram/issues/11
const preventCloseWithSwipe = () => {
  document.body.style.overflowY = 'hidden';
  document.body.style.marginTop = `${overflow}px`;
  document.body.style.height = window.innerHeight + overflow + "px";
  document.body.style.paddingBottom = `${overflow}px`;
  window.scrollTo(0, overflow);
}

export {preventCloseWithSwipe}