// src/components/ToastComponent.jsx
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TOAST_HIDE } from '../redux/actions/toastActions';

import DynamicPopUpComponent from './UI/DynamicPopUpComponent'; // Adjust the path as necessary


const ToastComponent = () => {
    const dispatch = useDispatch();
    const { title, message, button, severity, show } = useSelector(state => state.Toast);


    console.log(title);

    const handleClose = () => {
        dispatch({ type: TOAST_HIDE });
    };

    if (!show) return null;
    
    const modalData = {
        status: 0,
        readDate: null,
        _id: "",
        createdDate: new Date().toISOString(),
        user: "",
        gameId: "",
        type: "toast",
        title: title,//severity.toLowerCase() === "danger" ? "Error" : "Notification",
        text: message,
        buttons: [
            {
                text: button,
                action: "toast_" + severity.toLowerCase()
            }
        ],
        action: "toast_" + severity.toLowerCase(),
        image: null,
        data: {},
        __v: 0        
    };

    console.log(modalData);

    return (
        <DynamicPopUpComponent
            isModalOpen={show}
            closeModal={handleClose}
            modalData={modalData}
        />
    );
};

export default ToastComponent;