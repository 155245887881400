// import { FaTelegram } from "react-icons/fa";
import DollarImage from "../../../assets/images/dollar.png";

const JoinChannelPopup = ({ isModalOpen, closeModal,selectedChannel,handleOnCheck,handleOnJoin,t, ...props }) => {
   return (
		<div className="absolute w-full left-0 bottom-0 bg-[#1b1f26] rounded-[1.5rem_1.5rem_0_0] border-t-2 border-t-[#ca953b] border-solid">
			<div className="custom-shadow p-7 relative">
				<div className="flex items-center justify-between rounded-t dark:border-gray-600">
					<button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-500 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal" onClick={closeModal} >
						<svg
							className="w-3 h-3"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 14 14"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
							/>
						</svg>
						<span className="sr-only">Close modal</span>
					</button>
				</div>
				{isModalOpen && (
					<div className="flex items-center justify-center flex-col gap-3">
						{/* <FaTelegram color={"#5463d8"} size={36} /> */}
						<img className="W-[100px] h-[100px]" src={selectedChannel?.icon} alt="" />
						<div>
							{/* <p className="text-center text-lg my-3">Claim your Daily <span className="text-[#f3b806]">5000+</span> coins Reward </p>  */}
							<p className="text-center text-2xl font-bold">
								{selectedChannel?.title}
							</p>
						</div>
					 <button className="flex items-center justify-center w-60 joinBtnBg text-[#ffff] " onClick={handleOnJoin}>
							<span className="font-semibold" >{t("earn.join_button")}</span>
						</button>
						<div className="flex flex-row justify-center items-center gap-2">
							<img
								src={DollarImage}
								alt="Doller Image"
								className="mb-0 mx-auto w-8 h-8"
							/>
							<span className="font-bold text-lg">
								+{(selectedChannel?.reward).toLocaleString()}
							</span>
						</div>
					</div>
				)}
				<button
					onClick={handleOnCheck}
					className="flex items-center justify-center w-60 text-[#ffff] CheckBtnBg text-[#f3b806] mt-3"
				>
					<span className="font-semibold text-sm">{t("earn.check_button")}</span>
				</button>
			</div>
		</div>
	);
};

export default JoinChannelPopup;
