import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'

import { checkRewardDataAction } from '../redux/actions/rewardActions'
import { updateUserRewardsAction, updateClaimInARow } from '../redux/actions/authActions'

// Images
import Close from "../assets/images/rewards_close_popup.svg"
import CoinCalenderImg from "../assets/images/coins_calender.png"
import rewardCoin from "../assets/images/reward_popup_coin.svg"
import { AddCoinAction } from '../redux/actions/gameActions'
import { fireConfetti } from '../utils/confetti'

import { 
    DailyRwrdClaimClickAction
} from '../redux/actions/googleAnalyticsActions'

const DailyRewardPopup = ({ closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const AuthState = useSelector((state) => state.Auth);
    const RewardState = useSelector((state) => state.Reward);

    const [totalRewards, setTotalRewards] = useState(0);
    const [displayClaimButton, setDisplayClaimButton] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formatter = new Intl.NumberFormat('en', { notation: 'compact', compactDisplay: 'short' });

    // Styles as per the current Day
    const styleDay = {
        today: "bg-gradient-to-b from-[rgba(128,128,128,0.2)] via-[rgba(201,201,201,0.2)] to-[rgba(255,255,255,0.2)] border-[#34C759] border-[1px] border-solid",
        pastDay: "bg-gradient-to-b from-[#30EE60] to-[#155625]",
        futureDay: "opacity-30 bg-gradient-to-b from-[rgba(128,128,128,0.2)] via-[rgba(201,201,201,0.2)] to-[rgba(255,255,255,0.2)]"
    }

    const handleClaimClick = () => {
        setIsLoading(true);
        const successcallback = (data) => {
            setIsLoading(false);

            const claimInARow = AuthState?.user?.claim_in_a_row ?? 0;  // Default to 0 if undefined

            const rewardCoins = data?.[0]?.last_amount_given;
            if (rewardCoins) {
                // Extract the rewards from the data
                const newRewards = data.map(reward => ({
                    reward: reward.reward,
                    total: reward.total,
                    day: reward.day,
                    last_day_given: reward.last_day_given,
                    last_amount_given: reward.last_amount_given,
                    claim_in_a_row: reward.claim_in_a_row,
                    max_reward: reward.max_reward
                }));
                // Dispatch action to update the rewards in AuthState
                dispatch(updateUserRewardsAction(newRewards));

                localStorage.setItem("dailyRewardTimeStamp", Date.now());
                
                dispatch(AddCoinAction({ amount: rewardCoins, coinType: 'daily_rewards' }));
                setTotalRewards((prev) => prev + rewardCoins);

                dispatch(DailyRwrdClaimClickAction({ day_num: claimInARow }));
                setDisplayClaimButton(false);

                // Increment claim in a row counter to match future login response
                dispatch(updateClaimInARow(claimInARow + 1));

                fireConfetti();
            }
        }
        const onFailed = () => {
            setIsLoading(false);
        }
        dispatch(checkRewardDataAction({successcallback, onFailed}))
    }

    useEffect(() => {
        const getTsFromLocal = localStorage.getItem("dailyRewardTimeStamp");
        
        const today = new Date().setHours(0, 0, 0, 0);
        const lastClaimedDay = new Date(parseInt(getTsFromLocal)).setHours(0, 0, 0, 0);
        
        if (today === lastClaimedDay) { // we already claimed today
            setDisplayClaimButton(false);
        } else {
            setDisplayClaimButton(true);
        }
    }, [])

    useEffect(() => {
        const reward = AuthState?.user?.rewards.reduce((accumulator, currVal) => {
            return accumulator + currVal.total
        }, 0);

        setTotalRewards(reward);
    }, [AuthState])
    
    // check if all rewards are already claimed
    let allRewardsClaimed = false;
       
    
    return (
        <div className='fixed -bottom-20 left-0 w-full h-full z-50 rounded-t-3xl overflow-hidden pt-[2px] transition-all ease-in-out' style={{ backgroundImage: "linear-gradient(90deg, #FCDF4C 0%, #EDCB40 3.16%, #D0A429 10.21%, #B98517 17.18%, #A96F0A 23.95%, #9F6203 30.44%, #9C5D00 36.32%, #A16305 41.25%, #B17315 47.6%, #CA8D2D 54.7%, #E6AA49 60.88%, #FCDF4C 76.29%, #CC9819 91.52%, #B57600 100%)" }}>
            <div className='bg-[rgba(22,26,28,1)] w-full h-full relative rounded-t-3xl'>
                <img className='absolute right-7 top-7 z-10' alt='daily reward popup close' width={25} height={25} src={Close} onClick={closeModal} />
                <div className='flex flex-col items-center text-white h-full overflow-auto px-3 pb-[120px]'>
                    <img alt='Coins Calender' width={256} height={256} src={CoinCalenderImg} className='-translate-y-8' />
                    <div className='-translate-y-14'>
                        <h2 className='font-bold text-4xl mb-4 text-center'>{t('daily_rewards_popup.title')}</h2>
                        <p className='mb-4 text-center'>{t('daily_rewards_popup.subtitle')}</p>
                        <div className='grid grid-cols-4 gap-[10px] w-full'>
                            {
                                RewardState?.reward_data?.rewards?.map((item, i) => {
                                    let dayStyle;                                                                        
                                    if (allRewardsClaimed) { 
                                        dayStyle = 'pastDay';
                                    } else{
                                        let claimInARow = AuthState?.user?.claim_in_a_row;
                                        if (claimInARow == 0) {
                                            if (i == 0) {
                                                dayStyle = displayClaimButton ? 'today' : 'pastDay';
                                            } else {
                                                dayStyle = 'futureDay';
                                            }
                                        } else {
                                            let cellIndex = i + 1;
                                            if (cellIndex == claimInARow) {
                                                dayStyle = 'pastDay';
                                            } else if (cellIndex == claimInARow + 1) {
                                                dayStyle = displayClaimButton ? 'today' : 'futureDay';
                                            } else if (cellIndex > claimInARow) {
                                                dayStyle = 'futureDay';
                                            } else {
                                                dayStyle = 'pastDay';
                                            }      
                                        }                       
                                    }                                    

                                    return (
                                        <div key={`reward_item_${i}`} className={`col-span-1 ${styleDay[dayStyle]} flex flex-col items-center text-white rounded-[20px] overflow-hidden py-2`}>
                                            <span className='text-xs font-semibold inline-block'>{t('daily_rewards_popup.day')} {i + 1}</span>
                                            <img alt='Reward Coin' width={34} height={34} src={rewardCoin} />
                                            <span className='text-xs font-semibold inline-block'>{formatter.format(item)}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                {
                    allRewardsClaimed
                    ?
                        <button disabled={true} className='w-[calc(100%-30px)] h-[70px] rounded-xl bg-[rgba(131,131,131,0.7)] absolute bottom-[90px] left-1/2 -translate-x-1/2 p-[6px] text-[#F2F2F2] text-[19px] leading-[23px] font-bold'>
                            {t('daily_rewards_popup.all_claimed')}
                        </button>
                    :
                    <>
                    {
                        displayClaimButton ? (
                            
                                <button 
                                    className='w-[calc(100%-30px)] h-[70px] rounded-xl bg-gradient-to-b from-[#404344] to-[#2F3233] absolute bottom-[90px] left-1/2 -translate-x-1/2 p-[6px]' 
                                    onClick={handleClaimClick}
                                    disabled={isLoading}
                                >
                                    <div className='relative w-full h-full rounded-xl p-[1.5px]' style={{ backgroundImage: "linear-gradient(0deg, #FCDF4C 0%, #EDCB40 3.16%, #D0A429 10.21%, #B98517 17.18%, #A96F0A 23.95%, #9F6203 30.44%, #9C5D00 36.32%, #A16305 41.25%, #B17315 47.6%, #CA8D2D 54.7%, #E6AA49 60.88%, #FCDF4C 76.29%, #CC9819 91.52%, #B57600 100%)" }}>
                                        <div className='bg-gradient-to-b w-full h-full rounded-xl flex items-center justify-center text-white font-bold text-2xl shadow-[inset_0_0_4px_rgba(0,0,0,0.6)]'>
                                            {isLoading ? <ClipLoader color="#fff" size={24} /> : t('daily_rewards_popup.claim_button')}  {/* Show loader or text */}
                                        </div>
                                    </div>
                                </button>
                            ) :
                            (
                                <button disabled={true} className='w-[calc(100%-30px)] h-[70px] rounded-xl bg-[rgba(131,131,131,0.7)] absolute bottom-[90px] left-1/2 -translate-x-1/2 p-[6px] text-[#F2F2F2] text-[19px] leading-[23px] font-bold'>
                                    {t('daily_rewards_popup.come_back_tomorrow')}
                                </button>
                            )
                        }
                    </>
                }
                
                
                
            </div>
        </div>
    )
}

export default DailyRewardPopup