import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LionImg from '../../assets/images/lion_money.png';
import DollarImage from '../../assets/images/dollar.png';
import Footer from '../../components/Footer/Footer';
import JoinChannelPopup from './elements/JoinChannelPopup';
import Loader from '../../components/UI/Loader';
import { checkEarnDataAction, getEarnDataAction } from '../../redux/actions/earnActions';
import { AddCoinAction, AddToLocalCoinsAction } from '../../redux/actions/gameActions';
import { UpdateBalanceAfterClaimAction } from '../../redux/actions/authActions';
import { UserPointsKey } from '../../general.js';
import { useBackButton } from '../../hooks/useBackButton.jsx';
import { useCoins } from '../../hooks/useCoins.js';
import { preventCloseWithSwipe } from '../../utils/preventCloseWithSwipe.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import {fireXsocialConfetti , fireTgChannelConfetti, fireConfetti } from '../../utils/confetti.js';
import './style.css';
import { checkRewardDataAction, getRewardDataAction } from '../../redux/actions/rewardActions.js';
import DailyRewardPopup from '../../components/DailyRewardPopup.jsx';
import { EarnClaimDailyClickAction, EarnPlayArcadeClickAction } from '../../redux/actions/googleAnalyticsActions.js';
import {CheckUserCoins} from '../../redux/actions/gameActions';

const Earn = () => {
  useBackButton("/earn", "/home");
  const EarnState = useSelector((state) => state.Earn);
  const AuthState = useSelector((state) => state.Auth);
  const [isVisibleJoinPopup, setIsVisibleJoinPopup] = useState(false);
  const [isDailyRewardPopupOpen, setIsDaliyRewardPopupOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { earn_data, loading } = EarnState;
  const { user } = AuthState;
  const { currentCoinsRef} = useCoins();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(CheckUserCoins());
    preventCloseWithSwipe();
    dispatch(getPopupMessageAction());
    if (!isVisibleJoinPopup) {
      dispatch(getEarnDataAction());
      dispatch(getRewardDataAction());
    }
  }, []);

  const handleJoinChannel = (channel) => {
    console.log("Debug" , "channel", channel);
    switch(channel.action_type) {
      case "xSocial":
        setSelectedChannel(channel);
        setIsVisibleJoinPopup(!isVisibleJoinPopup);
        break;
      case "xTelegram":
        setSelectedChannel(channel);
        setIsVisibleJoinPopup(!isVisibleJoinPopup);
        break;
      case "navigate":
        dispatch(EarnPlayArcadeClickAction())
        navigate(channel.action_url)
        navigate
        break;
      case "reward_check":
        dispatch(EarnClaimDailyClickAction())
        setSelectedChannel(channel);
        setIsDaliyRewardPopupOpen(true);
        break;
      default:
        setSelectedChannel(channel);
        setIsVisibleJoinPopup(!isVisibleJoinPopup);
        break;
    }
  };

  const handleCloseJoinChannelPoup = () => {
    setSelectedChannel({});
    setIsVisibleJoinPopup(false);
  };

  const handleCloseDailyRewardPopup = () => {
    setSelectedChannel({});
    setIsDaliyRewardPopupOpen(false);
  };

  const handleOnJoin = () => {
    if (selectedChannel.action_type === "xSocial") {
      localStorage.setItem("earnPage_xSocial", true);
    }
    const channelUrl = selectedChannel?.action_url;
    let channelLink;
    if (channelUrl && channelUrl.includes("t.me")) {
      channelLink = Telegram.WebApp.openTelegramLink(channelUrl);
    } else {
      channelLink = Telegram.WebApp.openLink(channelUrl);
    }
  };

  const handleOnCheck = () => {
    if (selectedChannel?.action_type === "xSocial" && !localStorage.getItem("earnPage_xSocial") ){
        onCheckSuccess(201);
    }else{
     dispatch(
        checkEarnDataAction(
          { user_id: user?.id, ref_type: selectedChannel?.action_type, channel_id: selectedChannel?.channel_id },
          onCheckSuccess
        )
      );
    };
  };

   const onCheckSuccess = (status, data) => {


    if (status !== 200) {
      setIsVisibleJoinPopup(false);
      setSelectedChannel({});

      let title, message;

      if (status === 201) {
        title = t('earn.you_need_to_subscribe_popup_title');
        message = t('earn.you_need_to_subscribe_popup_text') ;
      } else if (status === 202) {
        title = t('earn.already_subscribed_popup_title');
        message = t('earn.already_subscribed_popup_text') ;
      }

      let okBtnTxt =  t('general.popup_ok');
      let cancelBtnTxt =  t('general.popup_cancel');

      window.Telegram.WebApp.showPopup(
        {
          title: title,
          message: message,
          buttons: [
            { id: "msg_ok", type: "default", text: okBtnTxt },
            { id: "cancel", text: cancelBtnTxt },
          ],
        },
        function (btn) {
          if (btn === "msg_ok") {
            
          }
        }
      );

      return;
    }

    const rewardCoins = selectedChannel?.reward;
    const newUserCoins = currentCoinsRef.current + rewardCoins;
    dispatch(AddToLocalCoinsAction(rewardCoins));
    dispatch(AddCoinAction({ amount: rewardCoins, coinType: "click" }));
    // let new_click_counts = getInitialClickCounts() + rewardCoins;
    // localStorage.setItem(ClickCountKey, new_click_counts);
    localStorage.setItem(UserPointsKey, newUserCoins);
    const newUserObject = {
      ...user,
      balance: newUserCoins
    };
    dispatch(UpdateBalanceAfterClaimAction(newUserObject));
    setIsVisibleJoinPopup(false);
    setSelectedChannel({});
    switch(selectedChannel.action_type) {
      case "xSocial":
        fireXsocialConfetti();
        break;
      case "tgChannel":
        fireTgChannelConfetti();
        break;
      default:
        fireConfetti();
        break;
    };

  };


  let EarnDataArr = [];
  if (earn_data && Object.keys(earn_data)) {
    Object.keys(earn_data).forEach((section) => {
      if (earn_data[section] && earn_data[section].length > 0) {
        let temItem = {};
        temItem["section"] = section;
        temItem["items"] = earn_data[section];
        EarnDataArr.push(temItem);
      }
    });
  }

  return (
    <div className="earnBg h-full text-white pt-0 pb-4 overflow-auto coustom-scroll ">
      <>
        {/* <div className="flex items-center justify-around py-0 px-1">
          <button className="flex items-center text-white text-xs p-2">
            <ArrowLeftIcon /></button>
            <div className="font-bold justify-center gap-2 flex items-center whitespace-nowrap text-md text-white">
              <img className="w-6 h-6" src={star} alt='star'/> Assad <img  className="w-6 h-6"  src={star} alt='star'/>
            </div>
          <Dropdownmenu />
        </div> */}
        <div className="wrapperScroll">
          <div className="bgShadow m-auto text-center">
            <img className="m-auto text-center" src={LionImg} alt="LionImg" />
            {/* <img
							src={DollarImage}
							alt="Doller Image"
							className="mt-12 mb-0 mx-auto w-12 h-12 shadow-[0_0_0_7.5px_rgb(252_219_90_/_65%),0_0_0_15px_rgb(172_147_57),0_0_20px_22.5px_rgb(127_107_38),0_0_20px_30px_rgba(242,255,11,0.2)] rounded-full"
						/> */}
          </div>
          <div className="px-[16px]">
            <h1 className="text-center text-[28px] font-semibold">
              {t("earn.title")}
            </h1>
            {loading ? (
              <>
                <div className="mt-8">
                  <Loader />{" "}
                </div>
              </>
            ) : (
              <>
                {EarnDataArr &&
                  EarnDataArr.length > 0 &&
                  EarnDataArr.map((item, index) => (
                    <div key={`section_${index}`} className="mt-[10px]">
                      <p className="font-semibold mb-[9px] text-[16px] leading-[16px]">
                        {item.section}
                      </p>
                      <div className="mb-[12.58px] flex flex-col gap-[10.5px]">
                        {item.items.map((channel, index) => (
                          <div
                            key={`item_${index}`}
                            className="rounded-2xl flex items-center justify-between bg-gradient-to-r from-[#454849] to-[#2d3032]  py-[12.36px] px-[20px] relative gradiant-border"
                            onClick={() => handleJoinChannel(channel)}
                          >
                            <div className="flex w-full items-center">
                              <span className='w-[59px] h-[57.69px] relative top-[2px] rounded-[10px] inline-flex items-center justify-center mr-[25px]'>
                                <img
                                  src={channel.icon}
                                  className="w-full h-full rounded-md"
                                  alt="option.icon"
                                />
                              </span>
                              <div className="flex flex-col gap-1.5">
                                <h3 className="text-[14px] leading-[16.21px] font-semibold">
                                  {channel.title}
                                </h3>
                                {
                                  channel?.id !== 13 && (
                                    <span className="text-[14px] leading-[14.21px]  text-[#fff] flex gap-[5px] items-center">
                                      <img
                                        src={DollarImage}
                                        alt="DollerIamge"
                                        className="h-auto w-[20px]"
                                      />
                                      {channel?.reward > 0 ? channel?.reward?.toLocaleString() : channel?.description}
                                    </span>
                                  )
                                }
                              </div>
                            </div>
                            {/*<FaChevronRight className="text-[#86898e]" />*/}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
        {!isVisibleJoinPopup && <Footer />}
      </>
      {isVisibleJoinPopup && (
        <JoinChannelPopup
          isModalOpen={isVisibleJoinPopup}
          closeModal={handleCloseJoinChannelPoup}
          selectedChannel={selectedChannel}
          handleOnCheck={handleOnCheck}
          handleOnJoin={handleOnJoin}
          t={t}
        />
      )}

      {isDailyRewardPopupOpen && (
        <DailyRewardPopup
          isModalOpen={isDailyRewardPopupOpen}
          closeModal={handleCloseDailyRewardPopup}
          selectedChannel={selectedChannel}
        />
      )}
    </div>
  );
};

export default Earn;
