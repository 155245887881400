import CryptoJS from 'crypto-js';
import { select } from 'redux-saga/effects';

const user = (state) => state.Auth.user;

export function* encryptPayload(payload) {
  const userData = yield select(user);
  
  if (!userData || !userData.id || !userData.accessToken) {
    throw new Error('User data is incomplete');
  }

  const key = CryptoJS.SHA256(userData.id + userData.accessToken).toString();
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(payload), key).toString();
  return { data: encryptedData };
}