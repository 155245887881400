import { useState } from 'react';
 

const JsonUpdateFormComponent = ({jsonData,cleareJsonData,fileName}) => {
    const [newUpdatedJsonData,setNewUpdatedJsonData] = useState(jsonData)
    
    const handleInputChange = (e, key, subKey,key1='') => {
        const value = e.target.value; 
        const newJson = newUpdatedJsonData;
        if(key1){ 
            //newUpdatedJsonData[key][key1][subKey] = value;   
            newJson[key] = { ...newJson[key],[key1]: { ...newJson[key][key1],[subKey]: value} };
        }else{ 
            newJson[key] = { ...newJson[key], [subKey]: value };
        }
        setNewUpdatedJsonData(newJson);
    };

    const getFileNameAndExtension=(filename)=> {
        const dotIndex = filename.lastIndexOf('.');
        
        if (dotIndex === -1) {
            return {
            name: filename,
            extension: ''
            };
        }
        
        const name = filename.substring(0, dotIndex);
        const extension = filename.substring(dotIndex + 1);
        
        return {
            name: name,
            extension: extension
        };
    }

    const handleSave = () => {
        if (!fileName) {
            //console.error('File name is missing.');
            return;
        }
    
        if (!newUpdatedJsonData || Object.keys(newUpdatedJsonData).length === 0) {
            //console.error('Updated JSON data is missing or empty.');
            return;
        }
    
        const { name, extension } = getFileNameAndExtension(fileName); 
        const jsonBlob = new Blob([JSON.stringify(newUpdatedJsonData, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(jsonBlob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = `${name}_new_updated.${extension}`;
        document.body.appendChild(link); // Attach link to the body
        link.click();
        document.body.removeChild(link); // Remove link after clicking
    
        URL.revokeObjectURL(url);
        cleareJsonData();
        setNewUpdatedJsonData({})
    };
    
    
    const isObject = (variable) => {
        return variable !== null && typeof variable === 'object' && !Array.isArray(variable);
    };

    const getTableRow = (key,subKey, subValue,key1="") => {
		return (
			<tr className="json-data-row">
				<td className="border px-4 py-2 w-10 ">{subKey}</td>
				<td className="border px-4 py-2 w-45  ">{subValue}</td>
				<td className="border px-4 py-2 w-45  "> 
					<textarea
						defaultValue={""}
						onChange={(e) => handleInputChange(e, key, subKey,key1)}
						className="w-full border px-2 py-1"
					/>
				</td>
			</tr>
		);
	};

    const tableContent = (key, subKey, subValue) => {
		return (
			<>
				<tr>
					<th colSpan={3} className="border px-4 py-2 w-100 ">{subKey}</th> 
				</tr>
				{Object.entries(subValue).map(([k, v]) => {
					return isObject(v) ? tableContent(key, k, v,subKey) : getTableRow(key, k, v,subKey);
				})}
			</>
		);
	};

    return (
        <div>
             
            <div className='jsonFormComponent flex flex-col gap-2'>
                <h1 className='font-bold'> JSON Editor</h1>
                <h2>{fileName} {` `} : {` `} 
                    <button className='bg-[#f72c2c] text-[#fff]    font-bold p-2 px-3 rounded' onClick={cleareJsonData} >Clear</button> 
                </h2>
                <div className="mt-4"> 
                {jsonData && (
                     <div>
                    {Object.keys(jsonData).map((key, index) => ( 
                        <div key={index} >
                            <h2 className="text-xl font-semibold  py-2">{key}</h2>
                            <table className="table-auto w-full mb-4 bg-[#fff]">
                                <thead>
                                <tr>
                                    <th className="border px-4 py-2 w-10 ">JSON Parameter</th>
                                    <th className="border px-4 py-2 w-45 ">Current Content</th>
                                    <th className="border px-4 py-2 w-45 ">New Content</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(jsonData[key]).map(([subKey, subValue]) => { 
                                        return isObject(subValue)  ? tableContent(key,subKey,subValue) : getTableRow(key,subKey, subValue) 
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ))}
                    <button
                        onClick={handleSave}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    >
                        Save and Download
                    </button>
                    </div>
                )}
                </div>
            </div> 
        </div>
    );
};

export default JsonUpdateFormComponent;
