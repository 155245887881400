import {
	CHECK_FRIENDS_REQUEST,
	CHECK_FRIENDS_SUCCESS,
	CHECK_FRIENDS_FAILED,
	CLAIM_REWARD_FRIENDS_REQUEST,
	CLAIM_REWARD_FRIENDS_FAILED,
	CLAIM_REWARD_FRIENDS_SUCCESS
} from "../actions/friendsActions";

const initialState = {
	loading: false,
	friendsList:[],
	claim_loading:false
};

export const FriendsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHECK_FRIENDS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CHECK_FRIENDS_SUCCESS:
			return {
				...state,
				loading: false,
				friendsList: action?.payload, 
			};
		case CHECK_FRIENDS_FAILED:
			return {
				...state,
				loading: false, 
			};
		case CLAIM_REWARD_FRIENDS_REQUEST:
			return {
				...state,
				claim_loading: true,
			};
		case CLAIM_REWARD_FRIENDS_SUCCESS:
			return {
				...state,
				claim_loading: false,
 			};
		case CLAIM_REWARD_FRIENDS_FAILED:
			return {
				...state,
				claim_loading: false, 
			};
		default:
			return state;
	}
};
