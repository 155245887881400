export const GET_LEVELS = "GET_LEVELS";
export const GET_LEVELS_NOT_FOUND = "GET_LEVELS_NOT_FOUND";
export const GET_LEVEL_FOUND = "GET_LEVEL_FOUND";

export const GET_LEVEL_LEADERBOARD = "GET_LEVEL_LEADERBOARD";
export const GET_LEVEL_LEADERBOARD_FOUND = "GET_LEVEL_LEADERBOARD_FOUND";
export const GET_LEVEL_LEADERBOARD_NOT_FOUND = "GET_LEVEL_LEADERBOARD_NOT_FOUND";

export const GET_USER_RANK_REQUEST = "GET_USER_RANK_REQUEST";
export const GET_USER_RANK_SUCCESS = "GET_USER_RANK_SUCCESS";
export const GET_USER_RANK_FAILED = "GET_USER_RANK_FAILED";

export const GetLevelsAction = (data) => {
  return { type: GET_LEVELS, payload: data };
};

export const GetLevelLeaderboardAction = (data) => {
  return { type: GET_LEVEL_LEADERBOARD, level: data.level, coins: data.coins };
};

export const getUserLeaderBoardRankAction = (data) => {
  return { type: GET_USER_RANK_REQUEST, payload: data };
};
