import { useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { Navigation, Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";

// Tinified images
import onboarding_img_1 from "../../assets/images/onboarding/onboarding_1.webp";
import onboarding_img_2 from "../../assets/images/onboarding/onboarding_2.png";
import onboarding_img_3 from "../../assets/images/onboarding/onboarding_3.png";
import onboarding_img_4 from "../../assets/images/onboarding/onboarding_4.png";

import default_bullet from "../../assets/images/onboarding/onboarding_default_bullet.svg";
import active_bullet from "../../assets/images/onboarding/onboarding_active_bullet.svg";
import startButton from "../../assets/images/btnBgBig.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./onboardingScreen.css";

const OnBoardingScreen = () => {

	const navigate = useNavigate();
	const [showNextButton, setShowNextButton] = useState(true);
	const AuthState = useSelector((state) => state.Auth);
	const { user } = AuthState;
	const { t } = useTranslation();

	const handleOnboardingComplete = () => {
		navigate(`/home`);
	};

	const slides = useMemo(
		
		() => [
			{
				image: onboarding_img_1,
				title: t("onboarding.screen1_title"),
				description: t("onboarding.screen1_desc"),
			},
			{
				image: onboarding_img_2,
				title: t("onboarding.screen2_title"),
				description: t("onboarding.screen2_desc"),
			},
			{
				image: onboarding_img_3,
				title: t("onboarding.screen3_title"),
				description: t("onboarding.screen3_desc"),
			},
			{
				image: onboarding_img_4,
				title: t("onboarding.screen4_title"),
				description: t("onboarding.screen4_desc"),
			},
		],
		[t]
	);

	const renderBullet = (index, className) => {
		return `<img src="${
			index === 0 ? active_bullet : default_bullet
		}" class="${className} m-0 w-3 h-3 md:w-4 md:h-4" />`;
	};

	const handleSlideChange = ({ pagination, activeIndex, isEnd }) => {
		const bullets = pagination.bullets;
		bullets.forEach((bullet, index) => {
			bullet.src = index <= activeIndex ? active_bullet : default_bullet;
		});

		setShowNextButton(!isEnd);
	};

	return (
		<div className="flex flex-col w-full h-full">
			<div className="grow" />
			<Swiper
				modules={[Navigation, Pagination]}
				spaceBetween={30}
				slidesPerView={1}
				navigation={{
					nextEl: ".onboarding-next-button",
					clickable: true,
				}}
				pagination={{
					clickable: true,
					renderBullet,
					el: ".onboarding-pagination",
				}}
				onSlideChange={handleSlideChange}
				className="h-auto"
			>
				{slides.map((slide, index) => (
					<SwiperSlide key={index} className="p-0">
						<div className="flex flex-col items-center justify-center pt-4 px-4">
							<h2 className="title text-[#F2F2F2] text-2xl text-center mb-2 font-bold">
								{slide.title}
							</h2>
							<p className="text-balance text-[#F2F2F2] text-lg text-center py-0 px-4">
								{slide.description}
							</p>
							<div className="container__img relative w-full h-auto -mt-2">
								<img src={slide.image} alt={slide.title} className="w-full" />
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<div className="grow bg-black relative z-10 shadow-[0_-2rem_3rem_3rem_black] flex justify-center">
				{!showNextButton && (
					<div
						className="absolute -top-16 flex justify-center items-center cursor-pointer w-52 h-14 mx-auto"
						onClick={handleOnboardingComplete}
					>
						<img
							src={startButton}
							alt="start-button"
							className="h-full w-full object-cover absolute inset-0"
						/>
						<span className="text-l font-bold whitespace-nowrap text-white relative">
							{t("onboarding.screen4_button")}
						</span>
					</div>
				)}
				<div className="grid grid-cols-3 w-full px-8 h-8 relative -top-3">
					<div />
					<div className="onboarding-pagination justify-center flex gap-1 items-center" />

					<div
						className={`onboarding-next-button relative shrink-0 ml-auto ${
							showNextButton ? "block" : "hidden"
						}`}
					/>
				</div>
			</div>
		</div>
	);
};

export default OnBoardingScreen;
