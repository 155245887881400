import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer/Footer';

const AuthContainer = () => {
  return (
    <div className="layout_wrapper flex justify-center h-screen overflow-auto items-center touch-none">
      <div className="panel_wrp">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default AuthContainer;
