import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import Footer from "../../components/Footer/Footer";
import tigerCoin from "../../assets/images/tigerCoin.png";
import wallet from "../../assets/images/wallet.png";
import comingSoon from "../../assets/images/comingSoonN.png";
import ArrowRightIcon from "../../icons/ArrowRightIcon";
import { useBackButton } from '../../hooks/useBackButton.jsx';
import { preventCloseWithSwipe } from '../../utils/preventCloseWithSwipe.js';
import { footerHeight } from '../../general.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import "./style.css";
import { GameConnectWalletClickAction } from '../../redux/actions/googleAnalyticsActions.js'
import {CheckUserCoins} from '../../redux/actions/gameActions';

const Airdrop = () => {
  useBackButton("/airdrop", "/home");
  const { t  } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CheckUserCoins());
    preventCloseWithSwipe();
    dispatch(getPopupMessageAction());
  }, []);

  const handleConnectWallet = () => {
    dispatch(GameConnectWalletClickAction())
  }

  return (
    <div className="earnBg h-full text-white overflow-auto pb-[70px] coustom-scroll" style={{height: `calc(100dvh - ${footerHeight}px)`}}>
      <div className="wrapperScroll">
        <div className="px-3">
        <img
          src={tigerCoin}
          alt="Doller Image"
          className="mt-20 mb-0 mx-auto h-24 w-24 shadow-[0_0_0_7.5px_rgb(252_219_90_/_65%),0_0_0_15px_rgb(172_147_57),0_0_60px_40px_rgb(127_107_38),0_0_20px_30px_rgba(242,255,11,0.2)] rounded-full"
        />
        <h1 className="mt-10 text-center text-2xl font-bold capitalize">{t('airdrop.title')}</h1>
        <p className="text-[#a5a5a5] text-sm font-semibold mt-2">{t('airdrop.description')}</p>

        <div className="mt-3 position-relative">
            <img className="comingSoon" src={comingSoon} alt="comingSoon"/>
          <label className="text-[#f2f2f2] text-md font-semibold mb-2">{t('airdrop.task_list')}</label>
          <button className=" afterBorder w-100 text-sm rounded-2xl justify-between font-semibold min-h-16 buttonBg
            flex items-center px-1.5 py-1" onClick={handleConnectWallet} >
            <div className="insideBorder p-0.5 w-100  justify-between flex items-center">
            <div className="d-flex items-center">
              <img className="h-14 w-14" src={wallet} alt='wallet'/>
              {t('airdrop.connect_wallet')}
                </div>
                <ArrowRightIcon/>
               </div>
            </button>

        </div>
        {/* {options.map((option, index) => (
          <div key={`lang_${index}`} className="mt-4">
            <p className=" font-bold">{option.title}</p>
            <div className="flex items-center justify-between gap-4 h-16 rounded-lg bg-[#272a2f] p-2 mt-3">
              <div className='flex gap-2 items-center'>
                <span><img src={option.icon} className="w-10 h-10 rounded-md" alt='option.icon'/></span>
                <div className="flex flex-col gap-1.5">
                  <h3 className="text-[13px]">{option.description}</h3>
                  <span className="text-[12px] text-[#fffff] flex gap-[5px] items-center"><img src={DollerIamge} alt="DollerIamge" className='h-5 w-5' />{option.coin}</span>
                </div>
              </div>
              <FaChevronRight className="text-[#86898e]" />
            </div>
          </div>
        ))}  */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Airdrop;
