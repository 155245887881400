import { useDispatch, useSelector } from "react-redux";
import {
  DecreaseEnergyAction,
  IncreaseEnergyAction,
  UpdateEnergyAction,
} from "../redux/actions/gameActions";

export const useEnergy = () => {
  const dispatch = useDispatch();
  const GameState = useSelector((state) => state.Game);

  const { energy } = GameState;

  const decrease = () => dispatch(DecreaseEnergyAction());
  const increase = () => dispatch(IncreaseEnergyAction());
  const update = (energy) => dispatch(UpdateEnergyAction(energy));

  return { energy, decrease, increase , update};
};
