
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux";
import { BrowserRouter } from 'react-router-dom';

import './i18n'; 

ReactDOM.createRoot(document.getElementById('root')).render(
  
    <Provider store={store}> 
      <PersistGate loading={null} persistor={persistor}> 
        <BrowserRouter> 
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider> ,
)
