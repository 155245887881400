import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import Progressbar from '../porgressbar';
import './style.css';

export default function Slider(props) {
  const {level, onLevelChange} = props;

  const swiperRef = useRef(null);
  const leaderboard = useSelector((state) => state.Leaderboard);

  const handleSlideChange = (e) => {
    const activeIndex = e.activeIndex
    const levelId = leaderboard.levels && leaderboard.levels[activeIndex].id;
    if (levelId !== undefined) {
      onLevelChange(levelId);
    }
  }

  const abbrNum = (number, decPlaces) => {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    const abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {
      // Convert array index to "1000", "1000000", etc
      const size = Math.pow(10, (i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round((number * decPlaces) / size) / decPlaces;

        // Handle special case where we round up to the next abbreviation
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }

        // Add the letter for the abbreviation
        number += abbrev[i];

        // We are done... stop
        break;
      }
    }

    return number;
  };

  return (
    <>
      <Swiper
        ref={swiperRef}
        speed={500}
        navigation
        initialSlide={level - 1}
        modules={[Navigation]}
        onSlideChange={handleSlideChange}
        className="mySwiper"
      >
        {leaderboard.levels.length > 0 &&
          leaderboard.levels.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="slideImage">
                  <img
                    className="w-[140px] h-[140px] max-h-[140px] object-fit-contain"
                    src={item.image_url}
                    loading="lazy"
                    alt={`slide${index}`}
                  />
                </div>
                <div className="flex flex-col items-center progressBarWrapper relative w-100">
                  <h2 className="text-2xl text-white font-semibold">
                    {item?.title}
                  </h2>
                  <p className="text-[#bfc3cb] text-xs font-semibold">
                    {abbrNum(item.min_range, 0)} - {abbrNum(item.max_range, 0)}
                  </p>                  
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
}
