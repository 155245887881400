import { useState,useRef,useEffect  } from 'react';
import './style.css'
import JsonUpdateFormComponent from './JsonUpdateFormComponent';

const JsonForm = () => {
    const [jsonData, setJsonData] = useState(null);
    const [error, setError] = useState(null);
    const [isFileLoaded, setIsFileLoaded] = useState(false);
    const [fileName, setFileName] = useState('');

    const fileInputRef = useRef(null); 
  
    const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFileName(file.name);

			const reader = new FileReader();
			reader.onload = (event) => {
				const content = event.target.result;
				try {
					const json = JSON.parse(content);
					setJsonData(json);
					setError(null);
					setIsFileLoaded(true);
				} catch (err) {
					setError("Invalid JSON file");
					setJsonData(null);
					setIsFileLoaded(false);
				}
			};
			reader.readAsText(file);
		}
	};


    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const cleareJsonData =()=>{
        setIsFileLoaded(false);
        setJsonData(null);
        setError(null);
        setFileName('');

    }

    useEffect(()=>{
        document.title='JSON Editor';
    },[])

    return (
        <div className={`jsonUpdatePage p-4 w-full ${isFileLoaded?'h-auto':'h-[100vh]' } `}>
            {
                isFileLoaded ? 
                <>
                    <JsonUpdateFormComponent isFileLoaded={isFileLoaded} setJsonData={setJsonData} jsonData={jsonData} cleareJsonData={cleareJsonData} fileName={fileName} />
                </>
                :
                <>
                <div className='flex flex-col gap-2 '>
                    <h1 className='font-bold'> JSON Editor</h1>
                    <h2>Load JSON File {` `} : {` `} 
                        <input type="file" accept=".json" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
                        <button className='bg-[#404cf1] text-[#fff] ml-2  font-bold p-2 px-3 rounded' onClick={handleButtonClick} >Browse</button> 
                    </h2>
                    <div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {jsonData && (
                            <div>
                            <h2>JSON Data:</h2>
                            <pre>{JSON.stringify(jsonData, null, 2)}</pre>
                            </div>
                        )}
                    </div>
                </div>
                </>
            }
            
        </div>
    );
};

export default JsonForm;
