export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_FOUND = "GET_USER_FOUND";
export const GET_USER_NOT_FOUND = "GET_USER_NOT_FOUND";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT_FAILED";

export const UPDATE_USER_AFTER_CLAIM = "UPDATE_USER_AFTER_CLAIM";

export const UPDATE_USER_LEVEL_REQUEST = "UPDATE_USER_LEVEL_REQUEST";

export const UPDATE_USER_BALANCE_REQUEST = "UPDATE_USER_BALANCE_REQUEST";

export const UPDATE_LANGUAGE_REQUEST = "UPDATE_LANGUAGE_REQUEST";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_FAILED = "UPDATE_LANGUAGE_FAILED";

export const UPDATE_USER_REWARDS = "UPDATE_USER_REWARDS";

export const UPDATE_CLAIM_IN_A_ROW = "UPDATE_CLAIM_IN_A_ROW";

export const LoginAction = (data) => {
	return { type: GET_USER_REQUEST, payload: data };
};

export const LogoutAction = (successcallback) => {
	return { type: LOGOUT, successcallback: successcallback };
};

export const DeleteAccountAction = (successcallback) => {
	return { type: DELETE_ACCOUNT_REQUEST, successcallback: successcallback };
};

export const UpdateBalanceAfterClaimAction = (userObject) => {
	return { type: UPDATE_USER_AFTER_CLAIM, payload: userObject };
};

export const updateUserLevelAction = (data) => {
	return { type: UPDATE_USER_LEVEL_REQUEST, payload: data };
};

export const UpdateLanguageAction = (data) => {
	return { type: UPDATE_LANGUAGE_REQUEST, payload: data };
};

export const updateUserRewardsAction = (newRewards) => ({
    type: UPDATE_USER_REWARDS,
    payload: newRewards,
});

export const updateClaimInARow = (data) => {
	console.log('updateClaimInARow:', updateClaimInARow);
	return { type: UPDATE_CLAIM_IN_A_ROW, payload: data };
};
