import { useEffect } from 'react';

export function useBackButton(currentPage, backPage) {
  useEffect(() => {
    sessionStorage.setItem("bckPage", backPage);
    sessionStorage.setItem("currentPage", currentPage);

    if (window.Telegram?.WebApp?.BackButton && !window.Telegram.WebApp.BackButton.isVisible) {
      if (backPage === 'close') {
        window.Telegram.WebApp.BackButton.hide();
      } else {
        window.Telegram.WebApp.BackButton.show();
      }
    }
  }, []);
}
