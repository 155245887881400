export const CHECK_FRIENDS_REQUEST = "CHECK_FRIENDS_REQUEST";
export const CHECK_FRIENDS_SUCCESS = "CHECK_FRIENDS_SUCCESS";
export const CHECK_FRIENDS_FAILED = "CHECK_FRIENDS_FAILED";

export const CLAIM_REWARD_FRIENDS_REQUEST = "CLAIM_REWARD_FRIENDS_REQUEST";
export const CLAIM_REWARD_FRIENDS_SUCCESS = "CLAIM_REWARD_FRIENDS_SUCCESS";
export const CLAIM_REWARD_FRIENDS_FAILED = "CLAIM_REWARD_FRIENDS_FAILED";
 
export const checkFriendsActionOld=()=> {
	return { type: CHECK_FRIENDS_REQUEST };
};

export const checkFriendsAction=(successcallback)=> {
	return { type: CHECK_FRIENDS_REQUEST,successcallback };
};

export const claimRewardActions= (data,successcallback,errorCallback) => {
	return { type: CLAIM_REWARD_FRIENDS_REQUEST, payload: data, successcallback,errorCallback };
}
 