import { call, put } from "redux-saga/effects";
import axios from "../../config/axiosConfig";

import { TOAST_SHOW } from "../actions/toastActions";
import { showErrorToast } from "../../utils/functions";
import {
  GET_LEVELS_NOT_FOUND,
  GET_LEVEL_FOUND,
  GET_LEVEL_LEADERBOARD_FOUND,
  GET_LEVEL_LEADERBOARD_NOT_FOUND,
  GET_USER_RANK_SUCCESS,
  GET_USER_RANK_FAILED
} from "../actions/leaderBoardActions";

export function* LeaderBoardSaga(action) {
  try {
    const response = yield call(axios.get, `get_levels`, action.payload);

    if (response?.status === 200) {
      yield put({
        type: GET_LEVEL_FOUND,
        payload: response?.data,
      });
    }
    if (response?.status === 404) {     
      yield* showErrorToast();
      yield put({ type: GET_LEVELS_NOT_FOUND }); 
      // yield put({
      //   type: TOAST_SHOW,
      //   payload: {
      //     message: "No result found.",
      //     severity: "Danger",
      //     show: true,
      //   },
      // });
    }
  } catch (e) {
    yield* showErrorToast();
    yield put({ type: GET_LEVELS_NOT_FOUND });
  }
}

export function* LevelLeaderboardSaga(action) {
  try {
    const response = yield call(
      axios.get,
      `get_level_leaderboard?level=${action.level}&coins=${action.coins}`
    );
    if (response?.status === 200) {
      yield put({
        type: GET_LEVEL_LEADERBOARD_FOUND,
        payload: response?.data?.leaders,
        userRank: response?.data?.user_rank,
      });
    }
    if (response?.status === 404) {
      yield* showErrorToast();
      yield put({ type: GET_LEVELS_NOT_FOUND });
      // yield put({
      //   type: TOAST_SHOW,
      //   payload: {
      //     message: "No result found.",
      //     severity: "Danger",
      //     show: true,
      //   },
      // });
    }
  } catch (e) {
    yield* showErrorToast();
    yield put({ type: GET_LEVEL_LEADERBOARD_NOT_FOUND });
  }
}


export function* getUserLeaderBoardRankSaga(action) {
  try {
    const response = yield call( axios.get, `check-user-rank?level=${action.levelId}`);
    if (response?.status === 200) {
      yield put({ type: GET_USER_RANK_SUCCESS, payload: response?.data});
    }
  } catch (e) {
    yield* showErrorToast();
    yield put({ type: GET_USER_RANK_FAILED });
  }
}
