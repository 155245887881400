import axios from "axios";

// Get the API base URL and version number from environment variables
const apiBaseUrl = `${import.meta.env.VITE_BASE_API_URL}`;
const getVersionNumber = () => import.meta.env.VITE_APP_VERSION;

const withAuthInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

withAuthInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");

    // Add the token to the headers if it exists
    if (token) {
      config.headers["x-access-token"] = token;
    } else {
      delete withAuthInstance.defaults.headers.common.Authorization;
    }

    config.headers["x-version"] = getVersionNumber();
    
    return config;
  },

  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

export default withAuthInstance;
