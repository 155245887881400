import { call, put, select, delay } from "redux-saga/effects";
import axios from "../../config/axiosConfig";
import {
	ADD_COIN_FAILED,
	ADD_COIN_SUCCESS,
	REPORT_CLICKS,
	REPORT_COINS_SUCCESS,
	REPORT_COINS_FAILED,
	SUBTRACT_COIN_FAILED,
	SUBTRACT_COIN_SUCCESS,
	GET_TOTAL_USERS_RESPONSE,
	CHECK_USER_COINS_RESPONSE,
	CHECK_USER_COINS_FAILED,
	CHECK_USER_COINS,
	REPORT_COINS_REQUEST,
} from "../actions/gameActions";
import { UPDATE_USER_BALANCE_REQUEST } from "../actions/authActions";
import { encryptPayload } from '../../utils/encryption';
import { showErrorToast } from "../../utils/functions";

let hasClicks = false;

const getCurrentCoins = (state) => state.Game.coins;
const getClicks = (state) => state.Game.clicks;
const getVersionNumber = () => import.meta.env.VITE_APP_VERSION;
const user = (state) => state.Auth.user;

export function* AddCoinSaga(action) {
	try {
		const currentCoins = yield select(getCurrentCoins);
		
		const { data } = yield* encryptPayload(action.payload);
		const response = yield call(axios.post, `add_coins`, { data });

		if (response?.status === 200) {
			const updatedCoins = response?.data.coins;

			if (updatedCoins > currentCoins) {
				yield put({
					type: ADD_COIN_SUCCESS,
					payload: response?.data,
				});
			} else {
				yield put({
					type: CHECK_USER_COINS_RESPONSE,
					payload: { coins: updatedCoins },
				});
			}
		}
	} catch (e) {
		yield* showErrorToast(); 
		yield put({ type: ADD_COIN_FAILED });
	}
}

export function* SubtractCoinSaga(action) {
	try {
		const currentCoins = yield select(getCurrentCoins);

		const { data } = yield* encryptPayload(action.payload);
		const response = yield call(axios.post, `subtract_coins`, { data });

		if (response?.status === 200) {
			const updatedCoins = response?.data.coins;

			if (updatedCoins >= currentCoins) {
				yield put({
					type: SUBTRACT_COIN_SUCCESS,
					payload: response?.data,
				});
			} else {
				yield put({
					type: CHECK_USER_COINS_RESPONSE,
					payload: { coins: updatedCoins },
				});
				yield put({
					type: REPORT_COINS_REQUEST,
					payload: { coins: "updatedCoins" },
				});
			}
		}
	} catch (e) {
		yield* showErrorToast(); 
		yield put({ type: SUBTRACT_COIN_FAILED });
	}
}

export function* ReportCoinSaga(action) {
	try {
		const currentCoins = yield select(getCurrentCoins);
		const version = getVersionNumber();

		const payloadToEncrypt = {
			...action.payload,
			version,
		};
		
		const { data } = yield* encryptPayload(payloadToEncrypt);
		const response = yield call(axios.post, "report_coins", { data });

		if (response?.status === 200) {
			const updatedCoins = response?.data.coins;

			if (updatedCoins >= currentCoins) {
				yield put({
					type: REPORT_COINS_SUCCESS,
					payload: {
						coins: response?.data?.coins,
						version: getVersionNumber(),
					},
				});
				yield put({
					type: UPDATE_USER_BALANCE_REQUEST,
					payload: action?.userObj,
				});
				localStorage.removeItem("click_count");
				localStorage.setItem("user_points", updatedCoins);
			} else {
				yield put({
					type: CHECK_USER_COINS_RESPONSE,
					payload: { coins: updatedCoins },
				});
			}
		}
	} catch (e) {
		yield* showErrorToast(); 
		yield put({ type: REPORT_COINS_FAILED });
	}
}

export function* getTotalUsersSaga(action) {
	try {
		const response = yield call(axios.get, "/get_total_users");

		if (response?.status === 200) {
			yield put({ type: GET_TOTAL_USERS_RESPONSE, payload: response?.data });
		}
	} catch (e) {
		yield put({ type: REPORT_COINS_FAILED });
	}
}

export function* checkUserCoinsSaga(action) {
	try {
		const response = yield call(axios.get, "/get_user_coins");

		if (response?.status === 200) {
			const serverCoins = response?.data.coins;
			const localCoins = yield select(getCurrentCoins);

			const maxCoins = Math.max(serverCoins, localCoins);
			yield put({
				type: CHECK_USER_COINS_RESPONSE,
				payload: { coins: maxCoins },
			});

			if (serverCoins < localCoins) {
				const userObj = {
					...yield select(user),
					balance: localCoins,
				};
				 yield put({
				 	type: REPORT_COINS_REQUEST,
				 	payload: { amount: localCoins, coinType : "reported_coins" ,version: getVersionNumber() },
					userObj					
				 });
			}
		}
	} catch (e) {
		yield put({ type: CHECK_USER_COINS_FAILED });
	}
}

export function* syncCoinsSaga() {
	while (true) {
		yield delay(60000);
		if (hasClicks) {
			//yield put({ type: REPORT_CLICKS });
			yield put({ type: CHECK_USER_COINS });
			hasClicks = false;
		}
	}
}

export function* trackClicksSaga() {
	hasClicks = true;
	const clicks = yield select(getClicks);
	if (clicks % 100 === 0) {
		yield put({ type: CHECK_USER_COINS });
	}
}

export function* initUserCoinsSaga() {
	yield put({ type: CHECK_USER_COINS });
}
