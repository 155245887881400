class TelegramState {
  _initializing = false;
  _initialized = false;
  _listeners = [];

  get initializing() {
    return this._initializing;
  }

  get initialized() {
    return this._initialized;
  }

  set initializing(value) {
    this._initializing = value;
  }

  set initialized(value) {
    this._initialized = value;
  }

  addCompleteListener(listener) {
    this._listeners.push(listener);
  }

  notifyListeners() {
    this._listeners.forEach(listener => listener());
  }
}

const telegramState = new TelegramState();

export { telegramState };
