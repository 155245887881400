import {
	GET_EARN_DATA_REQUEST,
	GET_EARN_DATA_FAILED,
	GET_EARN_DATA_SUCCESS,
	CHECK_EARN_DATA_REQUEST,
	CHECK_EARN_DATA_SUCCESS,
	CHECK_EARN_DATA_FAILED
} from "../actions/earnActions";

const initialState = {
	loading: false,
	check_loading: false,
	earn_data: {},
};

export const EarnReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_EARN_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_EARN_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				earn_data: action?.payload, 
			};
		case GET_EARN_DATA_FAILED:
			return {
				...state,
				loading: false,
				earn_data: {},
			};
		case CHECK_EARN_DATA_REQUEST:
			return {
				...state,
				check_loading: true,
			};
		case CHECK_EARN_DATA_SUCCESS:
			return {
				...state,
				check_loading: false, 
			};
		case CHECK_EARN_DATA_FAILED:
			return {
				...state,
				check_loading: false, 
			};
		default:
			return state;
	}
};
