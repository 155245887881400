export const RESET_REWARDS_DATA_REQUEST = "RESET_REWARDS_DATA_REQUEST";
export const RESET_REWARDS_DATA_SUCCESS = "RESET_REWARDS_DATA_SUCCESS";
export const RESET_REWARDS_DATA_FAILED = "RESET_REWARDS_DATA_FAILED";

export const SET_REWARD_DAY_REQUEST = "SET_REWARD_DAY_REQUEST";
export const SET_REWARD_DAY_SUCCESS = "SET_REWARD_DAY_SUCCESS";
export const SET_REWARD_DAY_FAILED = "SET_REWARD_DAY_FAILED";

export const resetRewardsDataAction = () => {
	return { type: RESET_REWARDS_DATA_REQUEST };
};

export const setRewardsDay = (days) => {
    return { type: SET_REWARD_DAY_REQUEST, days };
}