import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { 
  GameGamesClickAction, 
  GameFiendsClickAction, 
  GameEarnClickAction, 
  GameAirdropClickAction
} from '../../redux/actions/googleAnalyticsActions'
import airdropIcon from '../../assets/images/buttons/airdrop.png';
import airdropPressedIcon from '../../assets/images/buttons/airdrop_pressed.png';
import earnIcon from '../../assets/images/buttons/earn.png';
import earnPressedIcon from '../../assets/images/buttons/earn_pressed.png';
import friendsIcon from '../../assets/images/buttons/friends.png';
import friendsPressedIcon from '../../assets/images/buttons/friends_pressed.png';
import gamesIcon from '../../assets/images/buttons/games.png';
import gamePressedIcon from '../../assets/images/buttons/games_pressed.png';

const Footer = () => {
  const { t  } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url = window.location.pathname;

  const navigationBtn = [
    {
      id: 1,
      onClick: () => {
        dispatch(GameGamesClickAction())
        navigate("/akedo-games")
      },
      icon: gamesIcon,
      activeIcon: gamePressedIcon,
      text: t('buttons.games')
    },
    {
      id: 2,
      onClick: () => {
        dispatch(GameFiendsClickAction())
        navigate("/friends")
      },
      icon: friendsIcon,
      activeIcon: friendsPressedIcon,
      text: t('buttons.friends')
    },
    {
      id: 3,
      onClick: () => {
        dispatch(GameEarnClickAction())
        navigate("/earn")
      },
      icon: earnIcon,
      activeIcon: earnPressedIcon,
      text: t('buttons.earn')
    },
    {
      id: 4,
      onClick: () => {
        dispatch(GameAirdropClickAction())
        navigate("/airdrop")
      },
      icon: airdropIcon,
      activeIcon: airdropPressedIcon,
      text: t('buttons.airdrop')
    },
  ];

  const handleAkedoGamesClick = () => {
    let auth_token = sessionStorage.getItem("token");
    if (auth_token) {
      //let direct_link = `https://www.akedo.gg/#/category/19000192?token=${auth_token}`;
      let _direct_link = `https://www.akedo.gg/#/category/19000192~${auth_token}`;
      window.location.href = _direct_link;
    }
  };

  return (
    <div className="footerWrapper absolute -translate-x-2/4  left-2/4 bottom-[0px]  max-w-xl flex justify-between items-center z-50  w-full px-[15px] pb-[20px] pt-[10px]">

    {/*  <div className="text-center text-[#85827d] relative" onClick={() => handleAkedoGamesClick()} >
        <img src={gamesIcon} alt="Play" className="w-16 h-16 mx-auto" />
        <div className='text-center text-[#fff] text-[8px] absolute top-[63%] wid right-[0%] w-full' >
          <div style={{lineHeight: 6 + "px"}}  className='text-center text-[#fff] text-[8px] w-[50%] font-bold mb-0 mt-0 ml-auto mr-auto'>
            {t('buttons.games')}
          </div>
        </div>
      </div>
     */}
      {navigationBtn.map((el) => (
        <div key={el?.id} className="text-center text-[#85827d] relative" onClick={el.onClick} >
          <img src={url === el?.route ? el?.activeIcon : el?.icon} alt="Home" className="w-16 h-16 mx-auto" />
          <div className='text-center text-[#fff] text-[8px] absolute top-[63%] wid right-[0%] w-full' >
            <div style={{lineHeight: 6 + "px"}}  className='text-center text-[#fff] text-[8px] w-[50%] font-bold mb-0 mt-0 ml-auto mr-auto '>
              {el?.text}
            </div>
          </div>
        </div>
      ))}

      {/* {(url && url !== "/friends") ? (
        <>
        </>
      ) : (
        <>
          <div
            className="text-center text-[#85827d]"
            onClick={() => handleAkedoGamesClick()}
          >
            <img src={icon1} alt="Exchange" className="w-16 h-16 mx-auto" />
          </div>
          <div
            className="text-center text-[#85827d]"
            onClick={() => navigate(`/friends`)}
          >
            <img src={friendsPressedIcon} alt="friends" className="w-16 h-16 mx-auto" />
          </div>
          <div
            className="text-center text-[#85827d]"
            onClick={() => navigate(`/earn`)}
          >
            <img src={earnPressedIcon} alt="earn" className="w-16 h-16 mx-auto" />
          </div>
          <div
            className="text-center text-[#85827d]"
            onClick={() => navigate(`/airdrop`)}
          >
            <img src={airdropPressedIcon} alt="airdrop" className="w-16 h-16 mx-auto " />
          </div>
        </>
      )} */}
    </div>
  );
};

export default Footer;
