import { combineReducers } from "redux";

import { AuthReducer } from "./AuthReducer";
import { GameReducer } from "./GameReducer";
import {
  LeaderboardReducer,
  LevelLeaderboardReducer,
} from "./LeaderboardReducer";
import { BoostersReducer } from "./BoostersReducer";
import { EarnReducer } from "./EarnReducer"; 
import { FriendsReducer } from "./FriendsReducer";
import { MessageReducer } from "./MessageReducer"
import { RewardReducer } from "./RewardReducer";
import { ToastReducer } from "./ToastReducer";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Game: GameReducer,
  Leaderboard: LeaderboardReducer,
  LevelLeaderboard: LevelLeaderboardReducer,
  Boosters:BoostersReducer,
  Earn:EarnReducer,
  Friends:FriendsReducer,
  Message:MessageReducer,
  Reward: RewardReducer,
  Toast: ToastReducer,
});

export default rootReducer;
