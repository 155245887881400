export const GET_REWARD_DATA_REQUEST = "GET_REWARD_DATA_REQUEST";
export const GET_REWARD_DATA_SUCCESS = "GET_REWARD_DATA_SUCCESS";
export const GET_REWARD_DATA_FAILED = "GET_REWARD_DATA_FAILED";

export const CHECK_REWARD_DATA_REQUEST = "CHECK_REWARD_DATA_REQUEST";
export const CHECK_REWARD_DATA_SUCCESS = "CHECK_REWARD_DATA_SUCCESS";
export const CHECK_REWARD_DATA_FAILED = "CHECK_REWARD_DATA_FAILED";

export const getRewardDataAction = () => {
	return { type: GET_REWARD_DATA_REQUEST };
};

export const checkRewardDataAction = ({ successcallback, onFailed }) => {
    return { type: CHECK_REWARD_DATA_REQUEST, successcallback, onFailed };
}