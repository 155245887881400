// src/redux/reducers/ToastReducer.js
import { TOAST_SHOW, TOAST_HIDE } from '../actions/toastActions';

const initialState = {
    message: '',
    severity: '',
    show: false,
};

export const ToastReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOAST_SHOW:
            return {
                ...state,
                message: action.payload.message,
                severity: action.payload.severity,
                title: action.payload.title,
                button: action.payload.button,
                show: action.payload.show,
            };
        case TOAST_HIDE:
            return {
                ...state,
                show: false,
            };
        default:
            return state;
    }
};